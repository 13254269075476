import _ from 'lodash'
import { z } from 'zod'
import { DatosPersistentes } from '@base/lib/datosPersistentes'
import RosettasATraductor from '@base/lib/rosetta'
import type { IdiomasDisponibles } from '@base/lib/rosetta'
import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'
import { ErrorAumentado } from '@base/lib/error'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '../lib/erroresNotificables'
import { crearStorage } from '@base/lib/localforage'
import type { Perfil, Usuario } from '@comun/types'

export const IngresoModosZod = z.enum(['ingreso', 'registro', 'recuperarPass'])
export type IngresoModos = z.infer<typeof IngresoModosZod>

// * Storage
const consoloRaiz = 'TAPI Cuenta'
const consoloColor = 'color: MediumSlateBlue'

// * Eventos
const emisorEventos = { ...emisorEventosObjeto }
const usuarioRef = ref<Usuario | null | undefined>(undefined)
const tokenRef = ref<string | null>(null)

// Estados computados
export const usuario = computed(() => usuarioRef.value)
export const token = computed(() => tokenRef.value)
export const usuarioID = computed(() => usuarioRef.value?.usuarioID)
export const email = computed(() => usuarioRef.value?.email)
export const perfil = computed(() => usuarioRef.value?.perfil)

export async function guardarToken(token: string | null) {
	try {
		tokenRef.value = token
		if (!token)
			await DatosPersistentes.borrar('token')
		else
			await DatosPersistentes.guardar('token', token)
	} catch (e) {
		console.error('Error en guardarToken', token, 'error:', e)
	}
}

const usuarioStorage = crearStorage('Usuario')

export async function recuperarToken() {
	try {
		const token = unref(tokenRef)
		if (token)
			return token

		const tokenRecuperado = await DatosPersistentes.leer('token')
		// consolo.log('recuperarToken, tokenRecuperado', tokenRecuperado)

		if (_.size(tokenRecuperado)) {
			tokenRef.value = tokenRecuperado
			guardarToken(tokenRecuperado)
			return tokenRecuperado
		}

		const tokenDeCambioDeVersion523 = await usuarioStorage.getItem<string>('token')
		if (tokenDeCambioDeVersion523) {
			tokenRef.value = tokenDeCambioDeVersion523
			usuarioStorage.clear()
			guardarToken(tokenDeCambioDeVersion523)
		}
		// Si el contexto indica web, y no hay token, obtener desde localStorage

		return tokenDeCambioDeVersion523
	} catch (e) {
		console.error('Error en recuperarToken', e)
	}
}


function IntegrarUsuario(usuario: Usuario) {
	consolo.log('IntegrarUsuario', usuario)
	usuarioRef.value = usuario
	UsuarioAPI.emit('usuario')
}

// API

const i18n = RosettasATraductor({
	muyCorto: {
		es: 'Muy corto',
		en: 'Too short',
		pt: 'Muito curto'
	},
	necesario: {
		es: 'Necesario',
		en: 'Required',
		pt: 'Necessário'
	},
	formatoInvalido: {
		es: 'Formato inválido',
		en: 'Invalid format',
		pt: 'Formato inválido'
	}
})

const UsuarioZod = z.any()

const RespuestaLecturaZod = z.discriminatedUnion('ok', [
	z.object({
		ok: z.literal(true),
		usuario: UsuarioZod,
		token: z.string()
	}),
	z.object({
		ok: z.literal(false),
		error: ErrorNotificableZod
	})
])

const RegistroZod = z.object({
	nombre: z
		.string({
			required_error: i18n('necesario'),
			invalid_type_error: i18n('formatoInvalido')
		})
		.min(2, { message: i18n('muyCorto') }),
	apellido: z
		.string({
			required_error: i18n('necesario'),
			invalid_type_error: i18n('formatoInvalido')
		})
		.min(2, { message: i18n('muyCorto') }),
	email: InputEmailZod,
	pass: InputPassZod
})
type Registro = z.infer<typeof RegistroZod>

async function Registrar(registro: Registro) {
	const fx = 'Registrar'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const { nombre, apellido, email, pass } = RegistroZod.parse(registro)

		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/crear`,
			method: 'post',
			data: { nombre, apellido, email, pass },
			headers: HeadersBase()
		})

		const parseoRespuesta = RespuestaLecturaZod.safeParse(respuesta)
		if (!parseoRespuesta.success) {
			consolo.error(`${fx} parseoRespuesta`, parseoRespuesta)
			throw new Error('Error al parsear respuesta')
		}
		if (!parseoRespuesta.data.ok) {
			const errorParseado = parseoRespuesta.data.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorParseado),
				codigo: errorParseado,
				icono: i18nIconos[errorParseado]
			})
			return false
		}


		const { usuario: usuarioRecibido, token } = parseoRespuesta.data

		const parseoUsuario = UsuarioZod.safeParse(usuarioRecibido)
		if (!parseoUsuario.success) {
			consolo.error(`${fx} parseoUsuario`, parseoUsuario)
			throw new Error('Error al parsear usuario')
		}
		const usuario = parseoUsuario.data

		await guardarToken(token)

		IntegrarUsuario(usuario)

		const gtmPush = useGtmPush()
		gtmPush('cuentaCreada', {
			nombreCompleto: [nombre, apellido].join(' '),
			nombre,
			apellido,
			email
		})
		return token
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

export const IngresoArgsZod = z.object({
	email: InputEmailZod,
	pass: InputPassZod
})
export type IngresoArgs = z.infer<typeof IngresoArgsZod>

const UsuarioIngresarOkTAPI = z.object({
	ok: z.literal(true),
	usuario: UsuarioZod,
	token: z.string()
})

const UsuarioIngresarErrorTAPI = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})
const _RespuestaIngresoZod = z.discriminatedUnion('ok', [
	UsuarioIngresarOkTAPI,
	UsuarioIngresarErrorTAPI
])
type RespuestaIngreso = z.infer<typeof _RespuestaIngresoZod>

async function Ingresar(ingreso: IngresoArgs): Promise<RespuestaIngreso> {
	const fx = 'Ingresar'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	consolo.log('ingreso', ingreso)
	try {
		const { email, pass } = IngresoArgsZod.parse(ingreso)
		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/ingresar`,
			method: 'post',
			data: { email, pass },
			headers: HeadersBase()
		})

		consolo.log('respuesta', respuesta)

		if (!respuesta.ok) {
			const errorParseado = UsuarioIngresarErrorTAPI.parse(respuesta)

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorParseado.error),
				codigo: errorParseado.error,
				icono: i18nIconos[errorParseado.error]
			})
			return respuesta
		}

		const respuestaParseada = UsuarioIngresarOkTAPI.safeParse(respuesta)

		if (!respuestaParseada.success) {
			consolo.error(`${fx}: error`, respuestaParseada.error)
			consolo.error(`${fx}: respuesta original`, respuesta)
			throw new Error('errorParseadoUsuario', {
				cause: respuestaParseada.error
			})
		}
		const { usuario, token } = respuestaParseada.data

		// Integrar usuario.
		consolo.log({ usuario, token })
		usuarioRef.value = usuario ?? null
		await guardarToken(token as string)
		UsuarioAPI.emit('usuario')

		return respuesta
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

const refrescandoUsuarioRef = ref<boolean>(false)
export const refrescandoUsuario = computed(() => unref(refrescandoUsuarioRef))

async function Refrescar(): Promise<boolean> {
	const fx = 'Refrescar'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		refrescandoUsuarioRef.value = true
		consolo.log('refrescandoUsuario', unref(refrescandoUsuarioRef))
		const token = await recuperarToken()
		if (!token)
			return false
		const headers = HeadersConAuth()

		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/refrescar`,
			method: 'get',
			headers
		})

		const RefrescarSesionRespuestaZod = z.discriminatedUnion('ok', [
			z.object({
				ok: z.literal(true),
				usuario: UsuarioZod,
				token: z.string().optional()
			}),
			z.object({
				ok: z.literal(false),
				error: ErrorNotificableZod
			})
		])

		const parseoRespuesta = RefrescarSesionRespuestaZod.safeParse(respuesta)
		if (!parseoRespuesta.success) {
			consolo.error(`${fx}: error`, parseoRespuesta.error)
			consolo.error(`${fx}: respuesta original`, respuesta)
			throw new Error('errorParseado', {
				cause: parseoRespuesta.error
			})
		}

		if (!parseoRespuesta.data.ok) {
			const errorID = parseoRespuesta.data.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloLaCargaDePerfil'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})

			const erroresQueCierranSesion = ['tokenExpirado', 'cuentaEliminada']
			if (erroresQueCierranSesion.includes(errorID))
				CerrarSesion()

			return false
		}

		const usuario = parseoRespuesta.data.usuario
		IntegrarUsuario(usuario)
		if (parseoRespuesta.data.token) await guardarToken(parseoRespuesta.data.token)
		
		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		await esperar(50)
		refrescandoUsuarioRef.value = false
		consolo.log('refrescandoUsuario', unref(refrescandoUsuarioRef))
		consolo.log(fx, 'fin')
	}
}

async function IngresarConToken(
	tokenAUtilizar: string,
	forzar = false
): Promise<boolean> {
	const fx = 'IngresarConToken'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		if (!forzar) {
			// Si ya hay un usuario logueado con el mismo token, no se hace nada
			if (token.value && token.value === tokenAUtilizar)
				return true
		}
		// Si ya hay un usuario logueado, cerrar sesión ?
		if (token.value && usuario.value)
			await CerrarSesion()

		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/cuentas/refrescar`,
			headers: Object.assign(HeadersBase(), {
				Authorization: `Bearer ${tokenAUtilizar}`
			})
		})

		const parserIngresoConToken = z.discriminatedUnion('ok', [
			z.object({
				ok: z.literal(true),
				usuario: UsuarioZod
			}),
			z.object({
				ok: z.literal(false),
				error: ErrorNotificableZod
			})
		])

		const parseoRespuesta = parserIngresoConToken.safeParse(respuesta)
		if (!parseoRespuesta.success) {
			consolo.error(`${fx}: error`, parseoRespuesta.error)
			consolo.error(`${fx}: respuesta original`, respuesta)
			throw new Error('errorParseado', {
				cause: parseoRespuesta.error
			})
		}
		const respuestaParseada = parseoRespuesta.data

		if (!respuestaParseada.ok) {
			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(respuestaParseada.error),
				codigo: respuestaParseada.error,
				icono: i18nIconos[respuestaParseada.error]
			})
			return false
		}

		usuarioRef.value = respuestaParseada.usuario ?? null
		await guardarToken(tokenAUtilizar)

		UsuarioAPI.emit('usuario')
		return true
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

async function CerrarSesion() {
	const fx = 'CerrarSesion'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

	try {
		await Promise.all([DatosPersistentes.vaciar(), guardarToken(null)])
		UsuarioAPI.emit('sesionCerrada')

		const usuarioConectado = unref(usuario)

		const gimID = unref('gimnasioID')

		if (usuarioConectado) {
			const gtmPush = useGtmPush()
			gtmPush('logout', {
				id: usuarioConectado.usuarioID,
				email: usuarioConectado.email,
				nombreCompleto: `${usuarioConectado.perfil.nombre} ${usuarioConectado.perfil.apellido}`,
				gimnasioID: gimID
			})
		}


		usuarioRef.value = null
		navigateTo('/')
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

const CambiarPass = {
	async pedirCodigo(email: string, passNuevo: string) {
		const fx = 'CambiarPass>pedirCodigo'
		consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
		try {
			const respuesta = await comoSiFueraPorAxios({
				url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/cambiarPass/pedirCodigo`,
				method: 'post',
				data: { email, passNuevo },
				headers: HeadersBase()
			})

			consolo.log(`${fx} respuesta`, respuesta)

			if (!respuesta.ok) {
				if (respuesta.error === 'emailNoRegistrado') {
					notificadorEnApp.error({
						titulo: i18nTapi('noSePudoSolicitarCambioDePassword'),
						texto: `${i18nTapi(
							'noExisteUnaCuentaConEsteEmail'
						)} "${email}" \r(${i18nTapi('peroPuedesCrearUna')})`,
						duracionSegundos: 10,
						icono: 'solar:mailbox-bold-duotone'
					})
				}
				return respuesta
			}
			return respuesta
		}
		finally {
			consolo.log(fx, 'fin')
		}
	},
	async conCodigo(email: string, codigo: string) {
		const fx = 'UsuarioAPI>cambiarPass>conCodigo'
		consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

		try {
			const respuesta = await comoSiFueraPorAxios({
				url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/cambiarPass/conCodigo`,
				method: 'post',
				data: { email, codigo },
				headers: HeadersBase()
			})
				


			const parserCambioDePass = z.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true)
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod,
					intentosRestantes: z.number().optional()
				})
			])

			const parseoRespuesta = parserCambioDePass.safeParse(respuesta)
			if (!parseoRespuesta.success) {
				consolo.error(`${fx}: error`, parseoRespuesta.error)
				consolo.error(`${fx}: respuesta original`, respuesta)
				throw new Error('errorParseado', {
					cause: parseoRespuesta.error
				})
			}
			const respuestaParseada = parseoRespuesta.data

			if (!respuestaParseada.ok) {
				notificadorEnApp.atencion({
					titulo: i18nTapi('cambioDePasswordFallido'),
					texto: i18nTapi(respuestaParseada.error),
					codigo: respuestaParseada.error,
					icono: {
						codigoInvalido: 'iconoir:password-error'
					}[respuestaParseada.error]
				})
			}

			return respuestaParseada
		}
		finally {
			consolo.log(fx, 'fin')
		}
	}
}

const EliminarCuenta = {
	async pedirCodigo(pass: string) {
		const fx = 'EliminarCuenta>pedirCodigo'
		consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
		try {
			const respuesta = await comoSiFueraPorAxios({
				url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/eliminar/pedirCodigo`,
				method: 'post',
				data: { pass },
				headers: HeadersConAuth()
			})
				


			if (!respuesta.ok) {
				if (respuesta.error === 'emailNoRegistrado') {
					notificadorEnApp.error({
						titulo: i18nTapi('noSePudoSolicitarCambioDePassword'),
						texto: `${i18nTapi(
							'noExisteUnaCuentaConEsteEmail'
						)} "${email}" \r(${i18nTapi('peroPuedesCrearUna')})`,
						duracionSegundos: 10,
						icono: 'solar:mailbox-bold-duotone'
					})
				}
				return respuesta
			}
			return respuesta
		}
		finally {
			consolo.log(fx, 'fin')
		}
	},
	async conCodigo(codigo: string) {
		const fx = 'EliminarCuenta>conCodigo'
		consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

		try {
			const respuesta = await comoSiFueraPorAxios({
				url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/eliminar/conCodigo`,
				method: 'post',
				data: { codigo },
				headers: HeadersConAuth()
			})
				


			const parserCambioDePass = z.discriminatedUnion('ok', [
				z.object({
					ok: z.literal(true)
				}),
				z.object({
					ok: z.literal(false),
					error: ErrorNotificableZod,
					intentosRestantes: z.number().optional()
				})
			])

			const parseoRespuesta = parserCambioDePass.safeParse(respuesta)
			if (!parseoRespuesta.success) {
				consolo.error(`${fx}: error`, parseoRespuesta.error)
				consolo.error(`${fx}: respuesta original`, respuesta)
				throw new Error('errorParseado', {
					cause: parseoRespuesta.error
				})
			}
			const respuestaParseada = parseoRespuesta.data

			if (!respuestaParseada.ok) {
				notificadorEnApp.atencion({
					titulo: i18nTapi('cambioDePasswordFallido'),
					texto: i18nTapi(respuestaParseada.error),
					codigo: respuestaParseada.error,
					icono: {
						codigoInvalido: 'iconoir:password-error'
					}[respuestaParseada.error]
				})
			}

			return respuestaParseada
		}
		finally {
			consolo.log(fx, 'fin')
			Refrescar()
		}
	}
}

async function GuardarIdioma(idiomaID: IdiomasDisponibles): Promise<boolean> {
	const fx = 'GuardarIdioma'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		// consolo.log(fx)
		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/idioma`,
			method: 'put',
			data: { idiomaID },
			headers: HeadersConAuth()
		})

		// consolo.log(`${fx} r`, r)
		return respuesta
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

async function UrlCargaAvatar(): Promise<string> {
	const fx = 'UrlCargaAvatar'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)
	// consolo.log(fx)
	try {
		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/cargaDeAvatar`,
			method: 'get',
			headers: HeadersConAuth()
		})

		consolo.log(`${fx} r`, respuesta)
		if (!respuesta.ok)
			throw respuesta.error

		return respuesta.url
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

const RespuestaEdicionZod = z.discriminatedUnion('ok', [
	z.object({
		ok: z.literal(true),
		usuario: UsuarioZod
	}),
	z.object({
		ok: z.literal(false),
		error: ErrorNotificableZod
	})
])

async function EditarPerfil(edicionPerfil: Partial<Perfil>): Promise<boolean> {
	const fx = 'EditarPerfil'
	consolo.log(`%c${consoloRaiz} ${fx}`, consoloColor)

	try {
		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/cuentas/perfil`,
			method: 'put',
			headers: HeadersConAuth(),
			data: {
				perfil: JSON.parse(JSON.stringify(edicionPerfil))
			}
		})

		consolo.log(`${fx} respuesta`, respuesta)

		const parseado = RespuestaEdicionZod.safeParse(respuesta)
		if (!parseado.success) {
			// Reportar
			consolo.error('Error no configurado')
			consolo.warn('respuesta', respuesta)
			consolo.warn('parseado.error', parseado.error)
			return false
		}

		const resultado = parseado.data
		if (!resultado.ok) {
			const errorParseado = UsuarioIngresarErrorTAPI.parse(resultado)

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorParseado.error),
				codigo: errorParseado.error,
				icono: i18nIconos[errorParseado.error]
			})
			return false
		}

		usuarioRef.value = resultado.usuario

		return resultado.ok
	}
	finally {
		consolo.log(fx, 'fin')
	}
}

export const UsuarioAPI = {
	...emisorEventos,
	IntegrarUsuario,
	Registrar,
	Ingresar,
	Refrescar,
	IngresarConToken,
	CerrarSesion,
	CambiarPass,
	EliminarCuenta,
	GuardarIdioma,
	UrlCargaAvatar,
	EditarPerfil
}