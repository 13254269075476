import _ from 'lodash'
import { z } from 'zod'
import type { UnwrapRef } from 'vue'

import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Apariencia } from 'capacitor-apariencia'
import { ErrorAumentado } from '@base/lib/error'
import { crearStorage } from '@base/lib/localforage'

const temaStore = crearStorage('temaStore')

const EstadoZod = z.literal('claro').or(z.literal('oscuro').or(z.literal(null)))
type Estado = z.infer<typeof EstadoZod>

export type ConfiguracionTema = 'claro' | 'oscuro' | null

const temaOscuroInicializadoRef = ref<boolean>(false)
export const TemaOscuroInicializado = computed(() => temaOscuroInicializadoRef.value)

const OSTemaDarks = ref<boolean>(false)
const predefinido = contextoApp.appConfig.tema
const temaPagina = ref<ConfiguracionTema>(null)
const preferido = ref<ConfiguracionTema>(null)
const bloqueado = ref<boolean>(!!predefinido)

const oscuroActivo = computed((): boolean => {
	const utemaPagina = unref(temaPagina)
	const upreferido = unref(preferido)
	const uOSTemaDarks = unref(OSTemaDarks)
	// const uBloqueado = unref(bloqueado)

	const activo = (() => {
		if (utemaPagina)
			return utemaPagina === 'oscuro'
		if (predefinido)
			return predefinido === 'oscuro'
		if (upreferido)
			return upreferido === 'oscuro'
		return uOSTemaDarks
	})()
	// consolo.log({ utemaPagina, predefinido, uBloqueado, upreferido, uOSTemaDarks, activo })
	return activo
})

let statusBarLight = contextoApp.appConfig.splashFondo;
let statusBarDark = contextoApp.appConfig.splashFondoOscuro;
let navBarLight = contextoApp.appConfig.splashFondo;
let navBarDark = contextoApp.appConfig.splashFondoOscuro;

export function encontrarColor(cssvar: string): string {
	const color = window.getComputedStyle(document.body).getPropertyValue(cssvar).trim()
	// consolo.log('encontrarColor', { cssvar, color })

	if (color === '#000')
		return '#000000'
	if (color === 'black')
		return '#000000'
	if (color === '#fff')
		return '#FFFFFF'
	if (color === 'white')
		return '#FFFFFF'
	return color
}

async function refrescarColores() {
	try {
		statusBarLight = typeof window === 'undefined' ? contextoApp.appConfig.splashFondo : encontrarColor('--bodyFondo-light')
		statusBarDark = typeof window === 'undefined' ? contextoApp.appConfig.splashFondoOscuro : encontrarColor('--bodyFondo-dark')

		navBarLight = statusBarLight
		navBarDark = statusBarDark

		// Si alguno tiene un length menor a 7, el color no es válido
		if (_.some([navBarLight, navBarDark, statusBarLight, statusBarDark], color => !color || color.length < 7))
			throw new ErrorAumentado('refrescarColores, colores no válidos', { datos: { navBarLight, navBarDark, statusBarLight, statusBarDark } })

		// consolo.log('colores refrescados', { navBarLight, navBarDark, statusBarLight, statusBarDark })
	} catch (e) {
		console.error(e)
	}
}

async function configurarHead() {
  const fx = 'configurarHead'
  // consolo.log(fx)
  try {
    const activo = unref(oscuroActivo)
    const colorDeTema = activo ? navBarDark : navBarLight

    // Determinar las clases a usar en <html> y <body>
    const clases = activo ? 'oscuro' : 'claro'

    // Actualizar el atributo class de <html> y <body>
    document.documentElement.className = clases
    document.body.className = clases

    // Actualizar meta[name="theme-color"]
    let metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (!metaThemeColor) {
      metaThemeColor = document.createElement('meta')
      metaThemeColor.setAttribute('name', 'theme-color')
      document.head.appendChild(metaThemeColor)
    }
    metaThemeColor.setAttribute('content', colorDeTema)

    // Actualizar meta[name="msapplication-TileColor"]
    let metaMsTileColor = document.querySelector('meta[name="msapplication-TileColor"]')
    if (!metaMsTileColor) {
      metaMsTileColor = document.createElement('meta')
      metaMsTileColor.setAttribute('name', 'msapplication-TileColor')
      document.head.appendChild(metaMsTileColor)
    }
    metaMsTileColor.setAttribute('content', colorDeTema)

    // consolo.log(fx, 'Configuración de head realizada correctamente.')
  } catch (e) {
    console.error(e)
  }
}


async function configurarStatusBarYNavigationBar() {
	try {
		const oscuroActivoAhora = unref(oscuroActivo)
		const colorFondo = oscuroActivoAhora ? statusBarDark : statusBarLight
		// consolo.log('configurarStatusBarYNavigationBar', 'oscuroActivoAhora', oscuroActivoAhora, 'colorFondo', colorFondo )

		// if (contextoApp.buildConfig.dispositivo !== 'android') {
		// 	// consolo.log('No es android, se aborta.')
		// 	return
		// }

		if (Capacitor.isPluginAvailable('StatusBar')) {
			const style = oscuroActivoAhora ? Style.Dark : Style.Light
			await Promise.all([
				await StatusBar.setStyle({ style }),
				await StatusBar.setBackgroundColor({ color: colorFondo }),
			])
		}

		await Apariencia.setStatusBarColor({ color: colorFondo })
		await Apariencia.setNavigationBarColor({ color: colorFondo })

		// if (Capacitor.isPluginAvailable('NavigationBar')) {
		// 	// await NavigationBar.setTransparency({ isTransparent: true })
		// 	await NavigationBar.setColor({
		// 		color: colorFondo,
		// 		darkButtons: oscuroActivoAhora
		// 	})
		// }
	}
	catch (e) { console.error(e) }
}

async function propagarTema() {
	if (typeof window === 'undefined') return
	const fx = 'propagarTema'
	// consolo.log(consoleRaiz, fx)
	try {
		await refrescarColores()
		await configurarStatusBarYNavigationBar()
		configurarHead()
	}
	catch (e) { console.error(e) }
}


function configurarPreferido(elegido: Estado) {
	// consolo.log('configurarPreferido', elegido)
	try {
		preferido.value = elegido
		if (elegido)
			temaStore.setItem('preferido', elegido)
		else temaStore.removeItem('preferido')
	}
	catch (e) { console.error(e) }
}

function identificarTemaOS(): boolean {
	const fx = 'identificarTemaOS'
	// consolo.log(`${consoleRaiz} ${fx}`)
	try {
		if (typeof window === 'undefined') {
			throw new ErrorAumentado(
				'No se puede identificar el tema por defecto en el servidor'
			)
		}
		const soportado
			= window.matchMedia
			&& typeof window.matchMedia === 'function'
		if (!soportado) {
			throw new ErrorAumentado(
				'No se puede identificar el tema por defecto en este navegador'
			)
		}

		OSTemaDarks.value = window.matchMedia('(prefers-color-scheme: dark)').matches
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
			OSTemaDarks.value = event.matches
		})
		return OSTemaDarks.value
	}
	catch (e) {
		console.error(e)
		throw e
	}
}

function teclasTemaOscuro(event: KeyboardEvent) {
	if (event.code === 'KeyD' && event.altKey) {
		const estadoNuevo = !oscuroActivo.value ? 'oscuro' : 'claro'
		// consolo.log('tema preferido cambiado a ', estadoNuevo)
		configurarPreferido(estadoNuevo)
	}
}

let recordado = false

async function recordarPreferido() {
	if (recordado)
		return
	const modoPreferidoRescatado = temaStore ? await temaStore.getItem<Estado>('preferido') : null
	const modoPreferidoRescatadoParseado = parsear(modoPreferidoRescatado)
	if (modoPreferidoRescatadoParseado)
		configurarPreferido(modoPreferidoRescatadoParseado)
	recordado = true
}

const windowResize = lodash.throttle(() => {
	EventosDeAppAPI.emitirEventoDeApp('windowResize', true)
}, 500)

const init = async () => {
	try {
		console.log('temaOscuro init')
		// * Solo en cliente
		if (import.meta.env.SSR)
			return

		// consolo.log('\n\n\ntemaOscuro init')

		identificarTemaOS()
		recordarPreferido()
		temaPagina.value = parsearTemaDeRuta(useRoute())
		document.addEventListener('keydown', teclasTemaOscuro)
		window.addEventListener('resize', windowResize)

		watch(oscuroActivo, () => {
			propagarTema()
		}, { immediate: true })

		return
	} catch (e) {
		console.error(e)
	} finally {
		temaOscuroInicializadoRef.value = true
	}
}

export const TemaOscuroAPI: {
	init: () => void
	configurarPreferido: (elegido: Estado) => void
	propagarTema: () => void
	refrescarTemaDeRuta: () => void

	activo: ComputedRef<boolean>
	bloqueado: ComputedRef<boolean>
	temaOS: ComputedRef<boolean>
	preferido: ComputedRef<ConfiguracionTema>
} = {
	init,
	configurarPreferido,
	propagarTema,
	refrescarTemaDeRuta: () => {
		// consolo.log('\ntemaOscuro refrescarTemaDeRuta')
		temaPagina.value = parsearTemaDeRuta(unref(useRouter().currentRoute))
	},

	activo: computed(() => oscuroActivo.value),
	bloqueado: computed(() => bloqueado.value),
	temaOS: computed(() => OSTemaDarks.value),
	preferido: computed(() => preferido.value)
}

function parsear(supuesto: unknown): Estado {
	const parseo = EstadoZod.safeParse(supuesto)
	if (!parseo.success)
		return null
	return parseo.data
}

type Ruta = UnwrapRef<ReturnType<typeof useRouter>['currentRoute']>

function parsearTemaDeRuta(ruta: Ruta): Estado {
	return parsear(ruta.meta.tema)
}

export const refrescarTemaDeRuta = () => {
	// consolo.log('\ntemaOscuro refrescarTemaDeRuta')
	temaPagina.value = parsearTemaDeRuta(unref(useRouter().currentRoute))
}