<template lang="pug">
.notificacion.colapsable.posr.radioXL.tocable(v-if="!notificacion.cerrada" :class="[notificacion.tipo]")
	.fondo.maximizar.z0.bradi.sombra

	.p1ud.posr.z2.bradi
		.jcsb.gap1rem
			.icono(v-if="notificacion.icono"
				:style="`font-size: ${notificacion.iconoMedida || '1.4em'}; ` + (notificacion.iconoColor ? `color: ${notificacion.iconoColor};` : '')"
				)
				Icon(:icon="notificacion.icono" :color="notificacion.iconoColor")

			.contenido.fa11
				.fwb.mb1rem {{ notificacion.titulo }}
				.fwr {{ notificacion.texto }}

				.mt1rem.fz08rem.op05(v-if="notificacion.codigo") {{ notificacion.codigo }}
			//* Cerrador
			.cerrador.fa00(v-if="notificacion.cerrable")
				Ui2Boton.mini.circular.casiAlAire(@click="cerrarNotificacion" :class="notificacion.tipo")
					template(#preIcono)
						.p02rem
							i-mdi:close-thick
				//- Ui2Boton.mini.circular.casiAlAire(@click="cerrarNotificacion" :class="claseSegunTipo(notificacion.tipo)")
				//- 	template(#preIcono)
				//- 		.p02rem
				//- 			i-mdi:close-thick
				//- UiBoton.mini.circular.casiAlAire(@click="cerrarNotificacion" :class="claseSegunTipo(notificacion.tipo)")
					template(#preIcono)
						.p02rem
							i-mdi:close-thick
.dn(v-else)
</template>

<script lang="ts" setup>
import { Icon } from '@iconify/vue'
import type { Temporizador } from '@base/lib/temporizador'

const props = defineProps<{
	notificacion: NotificacionEnApp
}>()

function cerrarNotificacion() {
	notificadorEnApp.cerrar(unref(props.notificacion).id)
}

// Temporizador de cierre
const temporizador = ref<Temporizador | null>()


function iniciarFuncionamiento() {
	// consolo.log('iniciarFuncionamiento')
	const ahora = dayjs()
	const t = unref(temporizador)
	if (t) {
		console.error('No se puede iniciar, ya está iniciado')
		return
	}
	const n = unref(props.notificacion)
	temporizador.value = {
		duracionLimite: dayjs.duration(n.duracionSegundos, 's'),
		tiempoAlInicio: ahora,
		ultimaMedicion: ahora,
		duracionTranscurrida: dayjs.duration(0),
		tiempoAlPausar: null,
		tiempoAlTerminar: null,
		estaPausado: false,
		estaTerminado: false,
		historial: []
	}
	window.requestAnimationFrame(transcurrir)
}

async function transcurrir() {
	// consolo.log('transcurrir')
	const ahora = dayjs()
	const t = unref(temporizador)
	if (!t)
		return
	// Abortar si está pausado
	if (t.estaPausado)
		return
	// Corre el tiempo
	const duracionDesdeUltimaMedicion = dayjs.duration(ahora.diff(t.ultimaMedicion))
	t.ultimaMedicion = ahora
	t.duracionTranscurrida = t.duracionTranscurrida.add(duracionDesdeUltimaMedicion)
	if (t.duracionLimite.subtract(t.duracionTranscurrida).milliseconds() <= 0) {
		consolo.info('Se acabó el tiempo')
		terminar(ahora)
		return
	}
	temporizador.value = t
	// Esperar 1 segundo
	await new Promise(resolve => setTimeout(resolve, 1000))
	// Seguir cronometrando
	window.requestAnimationFrame(transcurrir)
}

function pausar() {
	consolo.log('pausar')
	const t = unref(temporizador)
	if (!t) {
		console.error('No se puede pausar si no existe')
		return
	}
	if (t.estaPausado)
		return

	const ahora = dayjs()
	// Registrar tiempo hasta la pausa
	const duracionDesdeUltimaMedicion = dayjs.duration(ahora.diff(t.ultimaMedicion))
	const duracionTranscurrida = t.duracionTranscurrida.add(duracionDesdeUltimaMedicion)

	t.ultimaMedicion = ahora
	t.duracionTranscurrida = duracionTranscurrida

	// Pausar
	t.estaPausado = true
	t.tiempoAlPausar = ahora
	t.historial.push({
		evento: 'pausa',
		tiempo: ahora
	})
	temporizador.value = t
}

function reanudar() {
	consolo.log('reanudar')
	const t = unref(temporizador)
	if (!t) {
		console.error('No se puede reanudar si no existe')
		return
	}
	if (!t.estaPausado) {
		console.error('No se puede reanudar si no está pausado')
		return
	}
	const ahora = dayjs()
	// Registrar ahora como el puevo punto de partida
	t.ultimaMedicion = ahora
	// Quitar pausa
	t.estaPausado = false
	t.tiempoAlPausar = null
	t.historial.push({
		evento: 'reanudacion',
		tiempo: ahora
	})
	temporizador.value = t

	window.requestAnimationFrame(transcurrir)
}

async function terminar(ahora = dayjs()) {
	consolo.log('terminar')
	if (!temporizador.value)
		return
	temporizador.value.tiempoAlTerminar = ahora
	cerrarNotificacion()
}

onMounted(() => {
	consolo.log('notificacion onMounted')
	iniciarFuncionamiento()
})

function claseSegunTipo(tipo: string) {
	switch (tipo) {
		case 'info':
			return 'bodyColor bodyFondo'
		case 'exito':
			return 'exitoColor exitoFondo'
		case 'atencion':
			return 'atencionColor atencionFondo'
		case 'error':
		case 'peligro':
			return 'peligroColor peligroFondo'
		default:
			return ''
	}
}
</script>

<style lang="sass" scoped>
@use '@base/sass/comun'
.notificacion
	backdrop-filter: blur(.5rem)
	+comun.compu
		min-width: min(24rem, 100dvw)
	.fondo
		opacity: 1
		background-color: var(--notificacionFondo, var(--bodyFondo, black))
		&::before
			content: ''
			display: block
			+comun.maximizar
			z-index: 1
			border-radius: inherit
			backdrop-filter: blur(.3rem)
			border-color: inherit
			border-width: 1px
			border-style: solid
			// Máscara gradiente radial, con origen arriba a la izquierda y transparente, alcanzando su completa opacidad al llegar al extremo contrario.
			mask-image: radial-gradient(circle at bottom left, transparent 20%, rgba(0,0,0,.9) 100%)
			// mask-image: radial-gradient(circle at bottom left, transparent -20%, rgba(0,0,0,1) 10%)
			transition: all .3s ease
			opacity: .15

.notificacion
	// +comun.alEntrar
	// 	.fondo
	// 		&::before
	// 			opacity: .5
	// 			// transition: all .3s ease 1s
	+comun.entrando
		.fondo
			&::before
				opacity: .5
				mask-image: radial-gradient(circle at bottom left,  rgba(0,0,0,.3) 0%, rgba(0,0,0,1) 100%)
				// transition: all .3s ease 1s
	+comun.alSalir
		.fondo::before
			opacity: 0
	&.info
		border-color: var(--notificacionColor, var(--bodyColor))
		color: var(--notificacionColor, var(--bodyColor))
		&::after
			border-color: var(--notificacionColor, var(--bodyColor))
			background-color: var(--notificacionFondo, var(--bodyFondo))
	&.exito
		border-color: var(--exitoColor, var(--exitoColor))
		color: var(--exitoColor, var(--exitoColor))
		&::after
			border-color: var(--exitoColor, var(--exitoColor))
			background-color: var(--exitoFondo, var(--exitoFondo))
	&.atencion
		border-color: var(--atencionColor, var(--atencionColor))
		color: var(--atencionColor, var(--atencionColor))
		&::after
			border-color: var(--atencionColor, var(--atencionColor))
			background-color: var(--atencionFondo, var(--atencionFondo))

	&.error,
	&.peligro
		border-color: var(--peligroColor, var(--peligroColor))
		color: var(--peligroColor, var(--peligroColor))
		&::after
			border-color: var(--peligroColor, var(--peligroColor))
			background-color: var(--peligroFondo, var(--peligroFondo))
</style>
