import { ContextoAppZod } from "../../../comun/estructuraContextoApp"
import contextoAppJson from '../assets/contextoApp.json'

const contextoAppParseo = ContextoAppZod.safeParse(contextoAppJson)

if (!contextoAppParseo.success) {
	console.error('Base layer > Error al parsear contextoApp.json', contextoAppParseo.error)
	console.error('issues', contextoAppParseo.error.issues)
	throw new Error('Base layer > Error al parsear contextoApp.json')
} else {

	// consolo.log('contextoAppParseo', contextoAppParseo.data)
}

export const contextoApp = contextoAppParseo.data
