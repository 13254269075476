<template lang="pug">
#bienvenidaRoot.minh100dvh
	slot
</template>

<script lang="ts" setup>
</script>

<script lang="ts">
export default defineComponent({
	name: 'BienvenidaLayout'
})
</script>

<style lang="sass" scoped>
</style>
