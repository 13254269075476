import throttle from 'lodash/throttle'

export type TiposDeNotificacionEnApp = 'exito' | 'info' | 'atencion' | 'error'

export type NotificacionEnApp = {
	id: string
	tipo: TiposDeNotificacionEnApp
	codigo?: string

	titulo: string
	texto?: string

	icono?: string
	iconoColor?: string
	iconoMedida?: string

	centrado?: boolean
	cerrable?: boolean
	cerrada?: boolean

	creacion?: Dayjs
	// vencimiento?: Dayjs
	duracionSegundos: number

	alCerrar?: () => void
	alAbrir?: () => void

	alAceptar?: () => void
	alCancelar?: () => void
}

const notificacionesEnAppRef = ref<Record<string, NotificacionEnApp>>({})
export const notificacionesEnApp = computed(() => unref(notificacionesEnAppRef))

const notificacionDefaults: Partial<NotificacionEnApp> = {
	centrado: false,
	cerrable: true,
	cerrada: false,
	duracionSegundos: 10,
	iconoMedida: '3em'
}

async function GenerarNotificacion(nuevaNotificacion: NotificacionEnApp) {
	const snap = unref(notificacionesEnAppRef)
	const notificacionID = nuevaNotificacion.id
	snap[notificacionID] = nuevaNotificacion
	notificacionesEnAppRef.value = snap
}

async function cerrar(notificacionID: string) {
	// Marcar notificación como cerrada
	const snap = unref(notificacionesEnAppRef)
	const notificacion = snap[notificacionID]
	notificacion.cerrada = true
	snap[notificacionID] = notificacion
	notificacionesEnAppRef.value = snap
}

// Type a partir de Notificacion, en que titulo y texto es requerido y las demás propiedades opcionales. id y tipo no se pueden especificar.
export type NotificacionData = Required<Pick<NotificacionEnApp, 'titulo' | 'texto'>> & Partial<Omit<NotificacionEnApp, 'id' | 'tipo' | 'titulo' | 'texto'>>

export const notificadorEnApp = {
	info: throttle(async function(data: NotificacionData) {
		const id = `info-${MiniID()}`
		const g: NotificacionEnApp = Object.assign(
			{
				...notificacionDefaults,
				id,
				tipo: 'info' as TiposDeNotificacionEnApp,
				icono: 'solar:info-square-line-duotone',
				creacion: dayjs(),
				duracionSegundos: 5
			},
			data
		)
		// consolo.log('Notificador.info', g)
		return GenerarNotificacion(g)
	}, 1000, { trailing: false }),

	exito: throttle(async function (data: NotificacionData) {
		const id = `exito-${MiniID()}`
		const g: NotificacionEnApp = Object.assign(
			{
				...notificacionDefaults,
				id,
				tipo: 'exito' as TiposDeNotificacionEnApp,
				icono: 'solar:check-circle-line-duotone',
				creacion: dayjs(),
				duracionSegundos: 3
			},
			data
		)
		// consolo.log('Notificador.exito', g)
		return GenerarNotificacion(g)
	}, 1000, { trailing: false }),

	atencion: throttle(async function (data: NotificacionData) {
		const id = `atencion-${MiniID()}`

		const g: NotificacionEnApp = Object.assign(
			{
				...notificacionDefaults,
				id,
				tipo: 'atencion' as TiposDeNotificacionEnApp,
				icono: 'solar:danger-triangle-line-duotone',
				creacion: dayjs(),
				duracionSegundos: 3
			},
			data
		)
		// consolo.log('Notificador.atencion', g)
		return GenerarNotificacion(g)
	}, 1000, { trailing: false }),

	error: lodash.throttle(async function (data: NotificacionData) {
		const id = `error-${MiniID()}`
		const g: NotificacionEnApp = Object.assign(
			{
				...notificacionDefaults,
				id,
				tipo: 'error' as TiposDeNotificacionEnApp,
				icono: 'solar:danger-circle-line-duotone',
				creacion: dayjs(),
				duracionSegundos: 3
			},
			data
		)
		// consolo.log('Notificador.error', g)
		return GenerarNotificacion(g)
	}, 1000, { trailing: false }),
	cerrar
}
