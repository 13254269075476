import _ from 'lodash'
import { ErrorAumentado } from '@base/lib/error'

import * as Comlink from 'comlink';
import type { ElWebWorker } from '@comun/webWorker'

const workerUrl = '/webWorker.mjs';

let worker: Worker | null = null;
let elWorker: Comlink.Remote<ElWebWorker> | null = null;

const initWorker = () => {
	if (!worker) {
		worker = new Worker(workerUrl);
		elWorker = Comlink.wrap(worker);
	}
};

export function webWorker(): Comlink.Remote<ElWebWorker> {
	initWorker()
	if (!elWorker) throw new Error('Worker no inicializado')
	return elWorker
}

const propagandoGimnasioActualizadoRef = ref(false)
export const propagandoGimnasioActualizado = computed(() => unref(propagandoGimnasioActualizadoRef))

async function propagarMensajeDePrueba() {
	const fx = 'propagarMensajeDePrueba'
	try {
		const ruta = `${contextoApp.buildConfig.apiUrl}/propagapi/propagarDePrueba`
		if (!token.value) {
			consolo.warn(fx, 'no hay token')
			throw new ErrorAumentado('noHayToken', { origen: fx })
		}

		propagandoGimnasioActualizadoRef.value = true
		const respuesta = await comoSiFueraPorAxios({
			url: ruta,
			headers: HeadersConAuth()
		})

		consolo.log(fx, 'r', respuesta)
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		propagandoGimnasioActualizadoRef.value = false
		consolo.groupEnd()
	}
}

async function propagarActualizacionDeGimnasio() {
	const fx = 'propagarActualizacionDeGimnasio'

	const gimID = unref(gimnasioID)
	consolo.log('gimID', gimID)
	try {
		if (!gimID) {
			consolo.warn(fx, 'no hay gimnasioID')
			return false
		}

		const ruta = `${contextoApp.buildConfig.apiUrl}/appaapp/gimnasio/${gimID}/refrescar`
		if (!token.value) {
			consolo.warn(fx, 'no hay token')
			throw new ErrorAumentado('noHayToken', { origen: fx })
		}

		propagandoGimnasioActualizadoRef.value = true
		const respuesta = await comoSiFueraPorAxios({
			url: ruta,
			headers: HeadersConAuth()
		})

		consolo.log(fx, 'r', respuesta)
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		propagandoGimnasioActualizadoRef.value = false
		consolo.groupEnd()
	}
}

async function suscribir() {
	const fx = 'suscribir'
	consolo.log(fx)
	
	try {
		const llavePublica = `${dayjs().format('YYYYMMDDHHmmss')}`

		const url = `${contextoApp.buildConfig.apiUrl}/propagapi/suscribir/${llavePublica}`
		consolo.log(fx, 'url', url)
		const respuesta = await webWorker().suscribirAlPropagador(url)

		consolo.log(fx, 'r', respuesta)
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		consolo.error(fx, e)
		throw new ErrorAumentado(fx, { error: e })
	}
	finally {
		propagandoGimnasioActualizadoRef.value = false
		consolo.groupEnd()
	}
}

export const SSEAPI = {
	propagarActualizacionDeGimnasio,
	propagarMensajeDePrueba,
	suscribir,
}