<template lang="pug">
.container.minh100dvh.aic.jcc
</template>

<script lang="ts" setup>

onMounted(async () => {
	const token = await recuperarToken()
	await new Promise(requestAnimationFrame)
	if (token)
		navigateTo('/app/horarios')
	// else if (!esAppGold)
	// 	navigateTo('/hola')
	else navigateTo('/acceso/ingreso')
})
useHead({
	bodyAttrs: {
		class: 'custom-body-class'
	}
})
definePageMeta({
	name: 'RootIndex'
})
</script>

<script lang="ts"></script>
