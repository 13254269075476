<template lang="pug">
#rootPaginaError.minh100dvh.aic.jcc.maxw100dvw.maxw100dvw
	.fa11.ffcn.gap6rem.aic.jcc.posr.z1.p1em.borde
		//- i-solar:city-bold-duotone.fz8rem.mb1rem
		.posr.z0.ffcn.aic.jcc
			div.posr.pb8rem
				i-material-symbols-light:water-lux.op06.z0.posr(style="font-size: 18rem")
				.posr
					i-solar:help-bold-duotone.fz8rem.peligroColor.posa.b0.z5(style="top: 100%; left: 50%; transform: translate(-30%, -40%) rotateZ(27deg)")

		.ffcn.gap2rem.tac.posr.z1.maxw100vw
			h1.fwl(v-if='error.statusCode === 404')
				| {{ i18n('paginaNoEncontrada') }}
			h1.fwl(v-else='')
				| {{ i18n('haOcurridoUnError') }}
			UiLink(para='/')
				Ui2Boton.primarioFondo.blancoColor.intocable() {{ i18n('irAlInicio') }}

			div.ovxa.tal(v-if="contextoApp.buildConfig.dev" style="max-width: 90dvw;")
				div.twb {{ error.message }}
				div.twb.wbbw(v-html="error.stack")
</template>

<script lang="ts" setup>
defineProps({
	error: {
		type: Object,
		default() {
			return null
		}
	}
})

// onMounted(() => {
// 	TemaOscuroAPI.propagarTema()
// })

const i18n = TrosettaAPI.crear({
	paginaNoEncontrada: {
		es: 'Página no encontrada',
		en: 'Page not found',
		pt: 'Página não encontrada'
	},
	haOcurridoUnError: {
		es: 'Ha ocurrido un error',
		en: 'An error has occurred',
		pt: 'Ocorreu um erro'
	},
	paginaDeInicio: {
		es: 'Página de inicio',
		en: 'Home page',
		pt: 'Página inicial'
	},
	irAlInicio: {
		es: 'Ir al inicio',
		en: 'Go to home',
		pt: 'Ir para o início'
	}
})
</script>

<style scoped lang="sass">
#rootPaginaError
	display: flex
	align-items: center
	justify-content: center
	text-align: center
	min-height: 100vh
	min-height: 100dvh
</style>
