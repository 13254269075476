import { trace } from '@opentelemetry/api';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { v4 as uuidv4 } from 'uuid';
import type { AxiosRequestConfig } from 'axios';

let tracerProvider: WebTracerProvider | null = null;
let tracer: ReturnType<WebTracerProvider['getTracer']> | null = null;

/**
 * Inicializa el sistema de trazado con las instrumentaciones necesarias.
 */
const inicializarTrazador = (nuxtHooks: any) => {
  if (!process.client) return; // Evitar inicialización en SSR
	if (tracerProvider) return; // Evitar inicialización múltiple
	
	consolo.log('Inicializando trazador...')

  tracerProvider = new WebTracerProvider();
  tracerProvider.register();
  tracer = tracerProvider.getTracer('nuxt-app');

  // Configurar instrumentación automática para interacciones de usuario
  const userInteractionInstrumentation = new UserInteractionInstrumentation();
  userInteractionInstrumentation.enable();

  // Configurar instrumentación automática para solicitudes fetch
  const fetchInstrumentation = new FetchInstrumentation();
  fetchInstrumentation.enable();

  // Configurar instrumentación automática para carga de documento
  const documentLoadInstrumentation = new DocumentLoadInstrumentation();
  documentLoadInstrumentation.enable();

  // Instrumentar hooks de Nuxt
  if (nuxtHooks) {
    Object.keys(nuxtHooks).forEach((hookName) => {
      nuxtHooks[hookName].use((...args: any[]) => {
        if (!tracer) throw new Error('El trazador no ha sido inicializado.');

        const span = tracer.startSpan(`nuxt-hook:${hookName}`);
        span.setAttributes({
          arguments: JSON.stringify(args),
        });
        span.end();
      });
    });
  }
};

/**
 * Registra un evento personalizado en el trazador.
 * @param nombre - Nombre del evento.
 * @param atributos - Atributos personalizados del evento.
 */
const registrarEvento = (nombre: string, atributos: Record<string, any> = {}) => {
  if (!tracer) {
    console.warn('El trazador no ha sido inicializado. Llama a inicializarTrazador primero.');
    return;
  }

  const span = tracer.startSpan(nombre);
  span.setAttributes(atributos);
  span.end();
};

/**
 * Instrumenta solicitudes realizadas por un worker con Axios.
 * @param config - Configuración de AxiosRequestConfig para la solicitud.
 */
const axiosWorkerConTrazado = async (config: AxiosRequestConfig) => {
  if (!tracer) {
    throw new Error('El trazador no ha sido inicializado. Llama a inicializarTrazador primero.');
  }

  const span = tracer.startSpan('axios-worker-request', {
    attributes: {
      url: config.url,
      method: config.method,
    },
  });

  try {
    const resultado = await axiosWorker(config); // Axios llamado en el worker
    span.setAttributes({
      status_code: resultado.status,
      success: true,
    });
    span.end();
    return resultado;
  } catch (error: any) {
    span.setAttributes({
      error: true,
      status_code: (error.response?.status || 500) as number,
      error_message: (error as Error).message,
    });
    span.end();
    throw error; // Relanzar el error para manejarlo en otro lugar
  }
};

/**
 * Composable de trazador
 */
export const useTrazador = (nuxtHooks?: any) => {
  if (process.client) inicializarTrazador(nuxtHooks);

  return {
    registrarEvento,
    axiosWorkerConTrazado,
  };
};
