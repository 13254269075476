import _ from 'lodash'
import { z } from 'zod'

import emisorEventosObjeto from '@base/lib/emisorEventosObjeto'


export const enCliente = import.meta.client

// * Eventos
const emisorEventos = { ...emisorEventosObjeto }

const BasePuestaEnMarchaRef = ref<boolean | null>(null)
const InicializandoBaseRef = ref<boolean | null>(null)

export const BasePuestaEnMarcha = computed(() => BasePuestaEnMarchaRef.value)
export const inicializandoBase = computed(() => InicializandoBaseRef.value)

async function init() {
	const fx = 'App base init'
	try {
		if (InicializandoBaseRef.value) {
			consolo.warn('Ya se está inicializando la app')
			return
		}

		InicializandoBaseRef.value = true
		consolo.log(fx)

		const windowResize = _.throttle(() => { EventosDeAppAPI.emitirEventoDeApp('windowResize', true) }, 500)
		window.addEventListener('resize', windowResize)

		await Promise.all([useTrazador(), TemaOscuroAPI.init()])
		armarHead(contextoApp)
		NotificacionesAPI.init()
		BasePuestaEnMarchaRef.value = true
	}
	catch (error) {
		consolo.error(fx, error)
		BasePuestaEnMarchaRef.value = false
	}
	finally {
		await esperar(100)
		InicializandoBaseRef.value = false
	}
}


export const AppZod = z.object({
	nombre: z.string(),
	textos: z
		.object({
			fraseInicio: z.string()
		})
		.optional(),

	permisosDeAnonimo: z.object({
		crearCuenta: BooleanOpcionalZod,
		ingresar: BooleanOpcionalZod,
		cambiarPass: BooleanOpcionalZod
	}).optional()
})

export type App = z.infer<typeof AppZod>

export const AppBaseAPI = {
	...emisorEventos,
	init
}

