import type { Gimnasio, RegistroDeGimnasios , RegistroDePerfilesEnGimnasio } from "@comun/types"
import _ from 'lodash'

// * Gimnasio

async function autoElegirPerfilEnGimnasio(perfilesEnGimnasios: RegistroDePerfilesEnGimnasio) {
	const fx = 'autoElegirPerfilEnGimnasio'
	consolo.log(fx)
	const timerID = `${fx} ${MiniID()}`
	consolo.time(timerID)
	try {
		// * Si hay un solo gimnasio con membresias vigentes, cambiar a ese
		const conMembresiasVigentes = _.pickBy(perfilesEnGimnasios, (peg) => {
			if (!peg.membresias)
				return false
			const mv = filtrarMembresiasVigentes(peg.membresias)
			return !_.isEmpty(mv)
		})

		if (_.values(conMembresiasVigentes).length === 1) {
			const peg = _.first(_.values(conMembresiasVigentes))!
			consolo.log('autoElegirPerfilEnGimnasio x conMembresiasVigentes', 'Seleccionando gimnasio', peg.gimnasioID)
			await AppAPI.SeleccionarGimnasio(peg!.gimnasioID)
			return
		}

		const conMembresias = _.pickBy(perfilesEnGimnasios, peg => !_.isEmpty(peg.membresias))

		if (_.values(conMembresias).length === 1) {
			const peg = _.first(_.values(conMembresias))!
			consolo.log('autoElegirPerfilEnGimnasio x conMembresias', 'Seleccionando gimnasio', peg.gimnasioID)
			await AppAPI.SeleccionarGimnasio(peg!.gimnasioID)
			return
		}

		if (_.values(perfilesEnGimnasios).length === 1) {
			const peg = _.first(_.values(perfilesEnGimnasios))!
			consolo.log('autoElegirPerfilEnGimnasio x perfilesEnGimnasios', 'Seleccionando gimnasio', peg.gimnasioID)
			await AppAPI.SeleccionarGimnasio(peg!.gimnasioID)
		}
	}
	finally {
		consolo.timeEnd(timerID)
	}
}

export async function PrepararSesionEnGimnasio() {
	const fx = 'PrepararSesionEnGimnasio'
	const timerID = MiniID()
	consolo.time(timerID)
	console.log(fx)
	try {
		const gID = unref(gimnasioID) ?? await AppAPI.RecuperarSeleccionDeGimnasio()
		const gim = unref(gimnasio)
		const peg = unref(MiPerfilEnGimnasio)
		const pegs = unref(perfilesEnGimnasios)


		const esSuperAdmin = unref(usuario)?.roles.includes('super_admin')
		if (esSuperAdmin) {
			consolo.log('esSuperAdmin')
			if (gID && !peg) await PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()
			else if (!gID) await GimnasiosAPI.ObtenerDisponibles()
		}
		else {
			const refrescarPegs = (!gID && _.isEmpty(pegs))

			if (refrescarPegs) consolo.log('🔄 refrescarPegs')

			const vinculadosRefrescados = refrescarPegs ? await (async () => {
				const pygs = await PerfilesEnGimnasioAPI.ObtenerVinculados()
				if (!pygs) return {}
				return pygs.perfilesEnGimnasios
			})() : pegs

			if (!gID) {
				if (!_.isEmpty(vinculadosRefrescados)) {
					consolo.log('🔄 autoElegirPerfilEnGimnasio vinculadosRefrescados', vinculadosRefrescados)
					await autoElegirPerfilEnGimnasio(vinculadosRefrescados)
				}
				else await GimnasiosAPI.ObtenerDisponibles()
			}
			
			const refrescarPeg = gID && (!refrescandoPerfilEnGimnasioPropio && gID && !peg) ? PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio() : null

			if (refrescarPeg) consolo.log('🔄 refrescarPeg')
			await refrescarPeg
		}
		if (gim && gim.caracteristicas.noticias) {
			const noticias = unref(useNoticias().noticias)
			if (_.isEmpty(noticias))
				NoticiasAPI.ObtenerNoticias()
		}
	}
	catch (e) {
		consolo.error(fx, e)
	}
	finally {
		consolo.log(fx)
		consolo.timeEnd(timerID)
	}
}


const ProcesadorDeEventos = function () {
	UsuarioAPI.on('sesionCerrada', async () => {
		const fx = 'UsuarioAPI.on(\'sesionCerrada\')'
		const timerID = MiniID()
		consolo.time(timerID)
		await Promise.all([
			AppAPI.DesconectarGimnasio(),
			GimnasioAPI.Limpiar(),
			UsuariosAPI.Limpiar(),
			PerfilesEnGimnasioAPI.Limpiar(),
			EspaciosAPI.Limpiar(),
			GimnasiosAPI.Limpiar(),
			NoticiasAPI.Limpiar(),
			InstanciasAPI.Limpiar(),
			RutinasAPI.Limpiar(),
			UsuariosAPI.Limpiar()
		])

		consolo.log(fx)
		consolo.timeEnd(timerID)
	})

	GimnasioAPI.on('cierreSesion', UsuarioAPI.CerrarSesion)

	GimnasiosAPI.on('gimnasiosDisponibles', async (gimsDispo: RegistroDeGimnasios) => {
		if (_.isEmpty(gimsDispo)) {
			consolo.log('No hay gimnasios disponibles')
			return
		}
		if (_.size(gimsDispo) === 1) {
			const gimnasioID = _.first(_.keys(gimsDispo))!
			const gimnasioIDActivo = unref(useGimnasio().gimnasioID)
			if (!gimnasioIDActivo)
				await AppAPI.SeleccionarGimnasio(gimnasioID)
		}
	})

	AppAPI.on('gimnasioID', async () => {
		const fx = 'AppAPI.on(\'gimnasioID\')'
		const timerID = MiniID()
		consolo.log(fx)
		consolo.time(timerID)

		try {
			GimnasioAPI.Limpiar()
			EspaciosAPI.Limpiar()
			NoticiasAPI.Limpiar()
			InstanciasAPI.Limpiar()
			RutinasAPI.Limpiar()
			UsuariosAPI.Limpiar()

			await new Promise(requestAnimationFrame)

			if (!unref(token)) {
				consolo.log('No hay token, se aborta')
				return
			}

			// const gim = unref(gimnasio)
			const pegs = unref(perfilesEnGimnasios)
			await Promise.all([
				// (!gim || gim.gimnasioID !== gID) ? (async () => {
				// 	consolo.log(fx, 'GimnasioAPI.ObtenerGimnasio()')
				// 	return GimnasioAPI.ObtenerGimnasio()
				// }) : null,

				(!pegs || _.isEmpty(pegs)) ? (async () => {
					consolo.log(fx, 'PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()')
					return PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()
				}) : null,
			])
		}
		finally {
			consolo.timeEnd(timerID)
		}
	})

	UsuarioAPI.on('usuario', async () => {
		const fx = 'UsuarioAPI.on(\'usuario\''
		consolo.log(fx)
		
		NotificacionesAPI.suscribir()
		SSEAPI.suscribir()
		await PrepararSesionEnGimnasio()
	})

	GimnasioAPI.on('gimnasio', async (_gimnasio: Gimnasio) => {
		// const fx = 'GimnasioAPI.on(\'gimnasio\')'
		// consolo.log(fx)
		await PrepararSesionEnGimnasio()
	})
}

export async function alInit() {
	consolo.log('alInit UsoDiario')
	ProcesadorDeEventos()

	await Promise.all([
		AppBaseAPI.init(),
		AppAPI.init()
	])
}