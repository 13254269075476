/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AxiosRequestConfig } from 'axios';

import { z } from 'zod'
import type { AxiosError } from 'axios'
import rosetta from '@base/lib/rosetta'
import { ErrorAumentado } from '@base/lib/error'
import type { ResultadoDeSolicitudViaWorker } from '@comun/webWorker';

export function HeadersConAuth(): Record<string, string> {
	if (!token.value)
		throw new ErrorAumentado('noHayToken')
	const headersBase = HeadersBase()
	return {
		...headersBase,
		Authorization: `Bearer ${token.value}`
	}
}

export function HeadersBase(): Record<string, string> {
	const { appConfig, buildConfig } = unref(contextoApp)
	const { appID } = appConfig
	const { dispositivo, version, modo } = buildConfig
	const headers: {
		[key: string]: string
	} = {
		'Accept': 'application/json',
		'Pow-App': appID,
		'Pow-Ambiente': modo,
		'Pow-Dispositivo': dispositivo,
		'Pow-Version': version
	}
	return headers
}

const i18nAxios = rosetta({
	errorDeRed: {
		es: 'Error de red',
		en: 'Network error',
		pt: 'Erro de rede'
	},
	sinRespuesta: {
		es: 'Sin respuesta',
		en: 'No response',
		pt: 'Sem resposta'
	},
	malaConfiguracion: {
		es: 'Mala configuración',
		en: 'Bad configuration',
		pt: 'Má configuração'
	},
	noSePudoConectarConAPI: {
		es: 'No se pudo conectar con API',
		en: 'Could not connect to API',
		pt: 'Não foi possível conectar à API'
	},
	noAutorizado: {
		es: 'No autorizado',
		en: 'Unauthorized',
		pt: 'Não autorizado'
	},
	solicitudRechazada: {
		es: 'Solicitud rechazada',
		en: 'Request rejected',
		pt: 'Solicitação rejeitada'
	}
})

const RespuestaConErrorZod = z.object({
	ok: z.literal(false),
	error: z.string()
})

const appNoAutorizadaRef = ref(false)
export const appNoAutorizada = computed(() => appNoAutorizadaRef.value)

function ManejadorErroresAxios(error: AxiosError) {
	const fx = 'base ManejadorErroresAxios'
	consolo.log(fx, 'error', error)

	if (error.toString().includes('Network Error')) {
		notificadorEnApp.error({
			titulo: i18nAxios('errorDeRed'),
			texto: i18nAxios('noSePudoConectarConAPI'),
			codigo: 'noSePudoConectarConAPI',
			duracionSegundos: 5,
			icono: 'arcticons:trexrunner',
			iconoMedida: '3rem'
		})
		throw new ErrorAumentado('sinConexion')
	}

	if (!error.response)
		throw new ErrorAumentado('sinRespuesta')

	const errorParseo = RespuestaConErrorZod.safeParse(error.response.data)

	if (!errorParseo.success)
		throw new ErrorAumentado('respuestaConErrorMalParseada', { datos: { responseData: error.response.data } })

	const codigo = errorParseo.data.error

	if (codigo === 'appNoAutorizada')
		appNoAutorizadaRef.value = true

	if (codigo === 'cuentaEliminada')
		UsuarioAPI.CerrarSesion()

	if (codigo === 'usuarioNoRegistrado') {
		const i18n = TrosettaAPI.crear({
			seHaCerradoLaSesion: {
				es: 'Se ha cerrado la sesión',
				en: 'Session has been closed',
				pt: 'A sessão foi encerrada'
			},
			usuarioNoRegistrado: {
				es: 'Usuario no encontrado',
				en: 'User not found',
				pt: 'Usuário não encontrado'
			}
		})
		notificadorEnApp.error({
			titulo: i18n('usuarioNoRegistrado'),
			texto: i18n('seHaCerradoLaSesion'),
			codigo,
			duracionSegundos: 3,
			icono: 'solar:ghost-outline',
			iconoMedida: '3rem'
		})
		UsuarioAPI.CerrarSesion()
		throw new ErrorAumentado('sinConexion')
	}

	return errorParseo.data
}

async function solicitar(config: AxiosRequestConfig): Promise<ResultadoDeSolicitudViaWorker> {
  const fx = 'solicitar'
	console.log(fx, config)
  try {
    if (!config.url) {
      throw new Error('URL is required');
    }

    const fetchConfig: RequestInit = {
      method: config.method?.toUpperCase() || 'GET',
      headers: new Headers(),
    };

    if (config.headers) {
      for (const [key, value] of Object.entries(config.headers)) {
        (fetchConfig.headers as Headers).append(key, value); // Type assertion 
      }
    }

    if (config.data) {
      // Convert data to JSON before assigning to body
			fetchConfig.body = JSON.stringify(config.data);
			(fetchConfig.headers as Headers).append('Content-Type', 'application/json')
    }

    // console.log(fx, 'fetchConfig', fetchConfig)
    const response = await fetch(config.url, fetchConfig);
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const responseData = await response.json();

    // Retornar solo los datos serializables
    const headersRespuesta: Partial<Headers> = lodash.pickBy(response.headers, (value) => typeof value === 'string');

    return {
      success: true,
      data: responseData,
      status: response.status,
      statusText: response.statusText,
      headers: headersRespuesta,
    };
  } catch (error) {
    const errorString = error instanceof Error ? error.message : 'Unknown error';
    return {
      success: false,
      error: errorString,
    };
  }
}

// API principal similar a Axios
export const axiosWorker = (config: AxiosRequestConfig) => {
	return solicitar(config)
		.then(r => r.data)
		.catch(ManejadorErroresAxios);
};

export const comoSiFueraPorAxios = (config: AxiosRequestConfig) => {
	return solicitar(config)
		.then(r => r.data)
		.catch(ManejadorErroresAxios);
};
