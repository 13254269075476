

export default defineNuxtPlugin((nuxtApp) => {
	// * Sólo en cliente
	if (!import.meta.client) return

	nuxtApp.hook('app:mounted', () => {
		// consolo.log('\n','01.inicio on app:mounted')

		// Inicio de la app
		alInit()
	})

	// * Detectar cambios de tema configurados en la página
	nuxtApp.hook('app:suspense:resolve', () => {
		// consolo.log('\non app:suspense:resolve')
		refrescarTemaDeRuta()
	})

	nuxtApp.hook('page:loading:end', () => {
		// consolo.log('\non page:loading:end')
		refrescarTemaDeRuta()
	})
})
