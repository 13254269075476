import { ContextoAppZod } from "../../../comun/estructuraContextoApp"
import contextoAppJson from '../assets/contextoApp.json'

const contextoAppParseo = ContextoAppZod.safeParse(contextoAppJson)

if (!contextoAppParseo.success) {
	console.error('usoDiario Error al parsear contextoApp.json', contextoAppParseo.error)
	console.error('issues', contextoAppParseo.error.issues)
	throw new Error('usoDiario Error al parsear contextoApp.json')
}

export const contextoApp = contextoAppParseo.data

export const esAppGold = !['boxmagic', 'members'].includes(contextoApp.appConfig.appID)