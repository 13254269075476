import _ from 'lodash'
import { App } from '@capacitor/app'
import type { URLOpenListenerEvent } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'

export default defineNuxtPlugin(() => {
	// Inicializar en cliente
	if (import.meta.client) {
		// * Deep Linking
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			const uri = new URL(event.url)
			const slug = uri.pathname

			// We only push to the route if there is a slug present
			if (slug) {
				const router = useRouter()
				router.push({
					path: slug
				})
			}
		})

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		App.addListener('appStateChange', async (state: any) => {
			consolo.log('state', state)
			if (state?.isActive) {
				AppAPI.emit('appActiva')
				consolo.log('Recarga de perfil ejecutada por reactivación de app')
				PerfilEnGimnasioAPI.ObtenerPerfilEnGimnasio()
				// GimnasioAPI.ObtenerGimnasio()
			}
			else if (state && !state.isActive) {
				AppAPI.emit('appInactiva')
			}
		})

		AppAPI.on('inicializada', async () => {
			// Hide the splash (you should do this on app launch)
			SplashScreen.hide()
		})

		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			consolo.log('appUrlOpen', event)
		})
	}
}) 