import type {
	Gimnasio,
	Participante
} from '@comun/types'

const participantesRef = ref<Record<string, Participante>>({})

export const participantes = computed(() => unref(participantesRef))
const ultimoUpdateParticipantesRef = ref<Dayjs | null>(null)

function IntegrarParticipantes(datos: {
	gimnasio: Gimnasio
	participantes: Record<string, Participante>
}) {
	const listaActualizable: Record<string, Participante> = participantesRef.value

	for (const [participanteID, participante] of Object.entries(datos.participantes)) {
		// if (participanteID === unref(usuarioID)) {
		// 	consolo.log('IntegrarParticipantes: Mismo usuario')
		// 	const u = unref(usuario)
		// 	const p = unref(MiPerfilEnGimnasio)
		// 	if (u && p) {
		// 		listaActualizable[participanteID] = {
		// 			miniusuario: u,
		// 			perfilEnGimnasio: p
		// 		}
		// 	}
		// 	// continue
		// } else
		if (listaActualizable[participanteID] && !participante.perfilEnGimnasio) {
			listaActualizable[participanteID].miniusuario = participante.miniusuario
			continue
		}
		listaActualizable[participanteID] = participante
	}
	participantesRef.value = listaActualizable
}

function miniusuarioPorID(elUsuarioID: string) {
	if (elUsuarioID === unref(usuarioID)) return unref(usuario)
	const participantes = unref(participantesRef)
	const participante = participantes[elUsuarioID]
	return participante?.miniusuario || null
}

async function Limpiar() {
	participantesRef.value = {}
	ultimoUpdateParticipantesRef.value = null
}

export const UsuariosAPI = {
	IntegrarParticipantes,
	Limpiar,
	miniusuarioPorID
}
