import _ from 'lodash'
import type { Dayjs } from 'dayjs';

import type {
	PagoMembresia,
	RegistroDeMembresias,
} from '@comun/types'

export function filtrarPagosConVigenciaRestante(
	pagos: Record<string, PagoMembresia>,
	fechaDeComparacion?: Dayjs,
): Record<string, PagoMembresia> {
	const lafecha = fechaDeComparacion || unref(hoy)
	const pagosConVigenciaRestante = _.pickBy(pagos, (pago) => {
		return lafecha.isBefore(pago.finVigencia)
	})
	return pagosConVigenciaRestante
}

export function filtrarMembresiasVigentes<
	Registro extends RegistroDeMembresias
>(
	membresias: Registro,
	fechaDeComparacion?: Dayjs
): Registro {
	const lafecha = fechaDeComparacion || unref(hoy)
	if (!membresias)
		return {} as Registro
	return _.pickBy(membresias, (membresia) => {
		if (!membresia.activa || !membresia.pagos)
			return false
		const pagosVigentes = filtrarPagosConVigenciaRestante(membresia.pagos, lafecha)
		return !_.isEmpty(pagosVigentes)
	}) as Registro
}


