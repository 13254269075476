import type { Directive } from 'vue';

interface ElementoConObservador extends HTMLElement {
  _observadorVisibilidad?: IntersectionObserver | null;
}

interface ArgumentosDirectiva {
  direccion?: 'vertical' | 'horizontal' | 'ambas';
  elementoLimite?: HTMLElement | null;
  unaVez?: boolean;
}

const monitorearVisibilidad: Directive<ElementoConObservador, ArgumentosDirectiva> = {
  mounted(elemento, enlace) {
    const { direccion = 'ambas', elementoLimite = null, unaVez = false } = enlace.value || {};
    let esVisible = false;
    let observador: IntersectionObserver | null = null;

    const manejarInterseccion: IntersectionObserverCallback = (entradas) => {
        //... (resto del código de la directiva)
    }

    observador = new IntersectionObserver(manejarInterseccion, { root: elementoLimite });
    observador.observe(elemento);
    elemento._observadorVisibilidad = observador;
  },
  unmounted(elemento) {
    if (elemento._observadorVisibilidad) {
      elemento._observadorVisibilidad.disconnect();
      delete elemento._observadorVisibilidad;
    }
  },
};

export default monitorearVisibilidad;