import { z } from 'zod'
import type { AxiosRequestConfig } from 'axios';
import rosetta from '@base/lib/rosetta'
import { ErrorAumentado } from '@base/lib/error'
import { ErrorNotificableZod, i18nIconos, i18nTapi } from '~/lib/erroresNotificables'
import { GimnasioZod, ListaDeParticipantesZod, PerfilEnGimnasioZod, UsuarioZod } from '@comun/types';
import type { ResultadoDeSolicitudViaWorker } from '@comun/webWorker';
import type { NotificacionData } from '@base/composables/notificadorEnApp';

export function HeadersConAuth(tkn?: string): Record<string, string> {
	const elToken = tkn ?? unref(token)
	if (!elToken)
		throw new ErrorAumentado('noHayToken', { origen: 'HeadersConAuth', alertar: false })

	const headersBase = HeadersBase()
	return {
		...headersBase,
		Authorization: `Bearer ${token.value}`
	}
}

export function HeadersBase(): Record<string, string> {

	const headers: {
		[key: string]: string
	} = {
		'Accept': 'application/json',
		'GoTs-App': contextoApp.appConfig.appID,
		'GoTs-Ambiente': contextoApp.buildConfig.modo,
		'GoTs-Dispositivo': contextoApp.buildConfig.dispositivo,
		'GoTs-Version': contextoApp.buildConfig.version,
	}

	const gimID = unref(gimnasioID)
	const gim = unref(gimnasio)
	const usr = unref(usuario)
	const peg = unref(MiPerfilEnGimnasio)

	if (gimID) headers['GoTs-Gimnasio'] = gimID
	if (gim && gim.marcaDeTiempo) headers['mdt-gim'] = gim.marcaDeTiempo
	if (usr && usr.marcaDeTiempo) headers['mdt-usr'] = usr.marcaDeTiempo
	if (peg && peg.marcaDeTiempo) headers['mdt-peg'] = peg.marcaDeTiempo

	return headers
}

const i18nAxios = rosetta({
	errorDeRed: {
		es: 'Error de red',
		en: 'Network error',
		pt: 'Erro de rede'
	},
	sinRespuesta: {
		es: 'Sin respuesta',
		en: 'No response',
		pt: 'Sem resposta'
	},
	malaConfiguracion: {
		es: 'Mala configuración',
		en: 'Bad configuration',
		pt: 'Má configuração'
	},
	noSePudoConectarConAPI: {
		es: 'No se pudo conectar con API',
		en: 'Could not connect to API',
		pt: 'Não foi possível conectar à API'
	},
	noAutorizado: {
		es: 'No autorizado',
		en: 'Unauthorized',
		pt: 'Não autorizado'
	},
	solicitudRechazada: {
		es: 'Solicitud rechazada',
		en: 'Request rejected',
		pt: 'Solicitação rejeitada'
	}
})

const RespuestaConErrorZod = z.object({
	ok: z.literal(false),
	error: z.string(),
	desconectar: z.literal(true).optional(),
})

const appNoAutorizadaRef = ref(false)
export const appNoAutorizada = computed(() => appNoAutorizadaRef.value)

const appNoConectadaSQLRef = ref(false)
export const appNoConectadaSQL = computed(() => appNoConectadaSQLRef.value)

const appNoConectadaMongoDBRef = ref(false)
export const appNoConectadaMongoDB = computed(() => appNoConectadaMongoDBRef.value)

function ManejadorErroresAxios(resultadoConError: ResultadoDeSolicitudViaWorker) {
	const fx = 'usoDiario ManejadorErroresAxios'
	consolo.log(fx, 'error', resultadoConError)

	if (!resultadoConError.error) return resultadoConError

	// Verificar si es un error de red
	if (resultadoConError.error === ('Network Error')) {
		notificadorEnApp.error({
			titulo: i18nAxios('errorDeRed'),
			texto: i18nAxios('noSePudoConectarConAPI'),
			codigo: 'errorDeRed',
			duracionSegundos: 5,
			icono: 'arcticons:trexrunner',
			iconoMedida: '3rem'
		});
		// return
		throw new ErrorAumentado('sinConexion');
	}

	// Verificar si hay respuesta disponible
	if (!resultadoConError.data) {
		notificadorEnApp.error({
			titulo: i18nAxios('sinRespuesta'),
			texto: i18nAxios('noSePudoConectarConAPI'),
			codigo: 'sinRespuesta',
			duracionSegundos: 5,
			icono: 'solar:server-path-line-duotone',
			iconoMedida: '3rem'
		});
		throw new ErrorAumentado('sinRespuesta');
	}

	const errorParseo = RespuestaConErrorZod.safeParse(resultadoConError.data);

	if (!errorParseo.success) {
		throw new ErrorAumentado('respuestaConErrorMalParseada', { datos: { responseData: resultadoConError.data } });
	}

	const codigo = errorParseo.data.error;
	consolo.log(fx, 'codigo', codigo);

	if (codigo === 'appNoAutorizada') {
		appNoAutorizadaRef.value = true;
	}

	const errorIDparseo = ErrorNotificableZod.safeParse(codigo)
	const errorID = errorIDparseo.success ? errorIDparseo.data : null


	if (errorID) {

		const errorBase: NotificacionData = {
			titulo: "Ups!",
			texto: i18nTapi(errorID),
			codigo: errorID,
			icono: i18nIconos[errorID],
		}

		console.error('errorID', errorID)
		// notificadorEnApp.atencion(errorBase)

		if (['conexionFallidaSDB', 'conexionRechazadaSDB'].includes(errorID)) {
			appNoConectadaSQLRef.value = true
		}
		if (codigo === 'conexionFallidaDB') {
			appNoConectadaMongoDBRef.value = true
		}
		if (errorID === 'gimNoAutorizado') {
			AppAPI.DesconectarGimnasio();
		}
		if (errorID === 'cuentaEliminada') {
			UsuarioAPI.CerrarSesion();
		}

		if (['conexionFallidaSDB', 'conexionRechazadaSDB', 'conexionFallidaDB'].includes(errorID)) {
			errorBase.titulo = i18nTapi('appDesactivada')
			notificadorEnApp.error(errorBase)
		} else if (['usuarioNoRegistrado', 'usuarioNoAutorizado'].includes(errorID)) {
			notificadorEnApp.error({
				titulo: i18nTapi(errorID),
				texto: errorParseo.data.desconectar ? i18nTapi('seHaCerradoLaSesion') : i18nTapi('seHaImpedidoElAcceso'),
				codigo: errorID,
				duracionSegundos: 3,
				icono: 'solar:ghost-outline',
				iconoMedida: '3rem'
			});

			UsuarioAPI.CerrarSesion();
			throw new ErrorAumentado('sinConexion');
		} else {
			// errorBase.titulo = 'Error'
			// notificadorEnApp.error(errorBase)
			console.error(errorBase)
		}
	}
	return errorParseo.data;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function IntegrarElementosFrescos(dataRecibida: any) {
	const fx = 'IntegrarElementosFrescos'
	try {
		const parser = z.object({
			usuario: UsuarioZod.optional(),
			perfilEnGimnasio: PerfilEnGimnasioZod.optional(),
			gimnasio: GimnasioZod.optional(),
			participantes: ListaDeParticipantesZod.optional(),
			desconectar: z.literal(true).optional()
		})
		const parseado = parser.safeParse(dataRecibida)

		if (parseado.success) {
			// Integrar la info que esté presente.'
			const resultado = parseado.data
			if (resultado.gimnasio) GimnasioAPI.IntegrarGimnasio(resultado.gimnasio)
			if (resultado.usuario) UsuarioAPI.IntegrarUsuario(resultado.usuario)
			if (resultado.perfilEnGimnasio) PerfilEnGimnasioAPI.IntegrarPerfilEnGimnasio(resultado.perfilEnGimnasio)

			if (resultado.desconectar) UsuarioAPI.CerrarSesion()
		}
	} catch (error) {
		console.error(fx, error)
	}
}

export async function falsoAxios(config: AxiosRequestConfig): Promise<ResultadoDeSolicitudViaWorker> {
	const fx = 'falsoAxios'
	console.log(fx, config)
	try {
		if (!config.url) {
			throw new Error('URL is required');
		}

		const fetchConfig: RequestInit = {
			method: config.method?.toUpperCase() || 'GET',
			headers: new Headers(),
		};

		if (config.headers) {
			for (const [key, value] of Object.entries(config.headers)) {
				(fetchConfig.headers as Headers).append(key, value); // Type assertion 
			}
		}

		if (config.data) {
			// Convert data to JSON before assigning to body
			fetchConfig.body = JSON.stringify(config.data);
			(fetchConfig.headers as Headers).append('Content-Type', 'application/json')
		}

		const response = await fetch(config.url, fetchConfig);
		const data = await response.json();

		console.log(fx, 'data', data)
		IntegrarElementosFrescos(data)

		const headersRespuesta: Partial<Headers> = lodash.pickBy(response.headers, (value) => typeof value === 'string');
		const comoEnAxios: ResultadoDeSolicitudViaWorker = {
			success: true,
			data: data,
			status: response.status,
			statusText: response.statusText,
			headers: headersRespuesta,
		};

		if (!data.ok) {
			ManejadorErroresAxios(comoEnAxios)
		}

		return comoEnAxios;
	} catch (error) {
		console.error(fx, 'catch', error);
		console.error('JSON error', JSON.stringify(error));


		const errorString = error instanceof Error ? error.message : 'Unknown error';
		return {
			success: false,
			error: errorString,
		};
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const comoSiFueraPorAxios = async (config: AxiosRequestConfig): Promise<any> => {
	consolo.log('usoDiario comoSiFueraPorAxios config', config)
	// Limpiar errores
	appNoAutorizadaRef.value = false
	appNoConectadaSQLRef.value = false
	appNoConectadaMongoDBRef.value = false

	try {
		const recibidoDelWorker: ResultadoDeSolicitudViaWorker = await falsoAxios(config)
		// consolo.log('recibidoDelWorker', recibidoDelWorker)
		return recibidoDelWorker.data
	} catch (error) {
		consolo.error('axiosWorkerAPI error', error)
		throw error
	}
}
