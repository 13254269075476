import type { PerfilEnGimnasio } from '@comun/types'
import mitt from 'mitt'

export type EventosEspecificos = {
  'instancia:abrirModal': {
    instanciaID: string
  },
  'rutaCambiada': string,
  'windowResize': boolean,
  'perfilesEnGimnasio': Record<string, PerfilEnGimnasio>
}

export type EventosDeApp = {
  [K in keyof EventosEspecificos]: EventosEspecificos[K]
} & {
  [key: string]: string
}

const emitter = mitt<EventosDeApp>()

// Lista de eventos pendientes
const eventosPendientes: { [K in keyof EventosDeApp]?: EventosDeApp[K][] } = {}

// Emitir eventos y acumularlos en la lista
const emitirEventoDeApp = <K extends keyof EventosDeApp>(tipo: K, payload: EventosDeApp[K]) => {
	consolo.log(`Emitiendo evento de app: ${tipo}`, payload)
  // Acumula el evento en la lista correspondiente
  if (!eventosPendientes[tipo]) {
    eventosPendientes[tipo] = []
  }
  eventosPendientes[tipo]!.push(payload)

  // Emitir el evento a través de mitt
  emitter.emit(tipo, payload)
}

// Capturar eventos y procesar la lista de eventos pendientes
const capturarEventoDeApp = <K extends keyof EventosDeApp>(tipo: K, handler: (payload: EventosDeApp[K]) => void) => {

	// Procesa los eventos pendientes
	if (eventosPendientes[tipo]) {
		consolo.log(`Procesando eventos pendientes de app: ${tipo}`)

		while (eventosPendientes[tipo]!.length > 0) {
			const evento = eventosPendientes[tipo]!.shift() // Elimina el evento de la lista
			consolo.log(`Procesando evento pendiente de app: ${tipo}`, evento)
			if (evento !== undefined) {
				handler(evento)
			}
		}
	}
	
  const handleEvent = (payload: EventosDeApp[K]) => {
    
		// Procesa el evento actual
		consolo.log(`Procesando evento de app: ${tipo}`, payload)
    handler(payload)
  }

  // Configura el capturador para futuros eventos
  emitter.on(tipo, handleEvent)
}

const limpiarEventosDeApp = () => {
	// Vaciar eventosPendientes
	for (const tipo in eventosPendientes) {
		delete eventosPendientes[tipo]
	}
}

export const EventosDeAppAPI = {
	emitirEventoDeApp,
	capturarEventoDeApp,
	limpiarEventosDeApp
}