import lodash from 'lodash'
import type {
	Gimnasio,
	Instancia,
	InstanciaCalculada,
	Membresia,
	PagoMembresia,
	Plan,
	PosibilidadDeReserva,
	ReservaEnPago,
	Reservabilidad
} from './types'

import type { Dayjs } from 'dayjs'
import dayjs from './fechas'

export function calcularCuposDelPago(datos: {
	plan: Plan
	pago: PagoMembresia
	fechaDeComparacion: Dayjs
	antelacionDeCancelacionMinutos?: number
}): {
	disponibles: number
	recuperables: number
	noRecuperables: number
} {
	const { plan, pago, fechaDeComparacion, antelacionDeCancelacionMinutos = 0 } = datos

	const reservasQueUsanCupo = lodash.filter(pago.reservas, reserva => !reserva.eliminacion && reserva.utilizaCupo)

	const segunRecuperabilidad = (() => {
		if (plan.cupoPorDia) {
			const reservasPorDia = lodash.groupBy(reservasQueUsanCupo, reserva => dayjs(reserva.fechaInicio).format('YYYY-MM-DD'))
			// * Para que un cupo sea recuperable, todas las reservas de ese dia deben ser cancelables
			const agrupadosPorRecuperabilidad = lodash.groupBy(reservasPorDia, reservasMismoDia => {
				return lodash.every(reservasMismoDia, reserva => {
					return fechaDeComparacion.isBefore(dayjs(reserva.fechaInicio).subtract(antelacionDeCancelacionMinutos || 0, 'minutes'))
				}) ? 'recuperables' : 'noRecuperables'
			})

			return {
				recuperables: lodash.size(agrupadosPorRecuperabilidad.recuperables),
				noRecuperables: lodash.size(agrupadosPorRecuperabilidad.noRecuperables)
			}
		}

		const agrupadosPorRecuperabilidad = lodash.groupBy(reservasQueUsanCupo, reserva => {
			return fechaDeComparacion.isBefore(dayjs(reserva.fechaInicio).subtract(antelacionDeCancelacionMinutos || 0, 'minutes')) ? 'recuperables' : 'noRecuperables'
		})

		return {
			recuperables: lodash.size(agrupadosPorRecuperabilidad.recuperables),
			noRecuperables: lodash.size(agrupadosPorRecuperabilidad.noRecuperables)
		}
	})()

	const alteracionDeCuposDelPago = pago.cuposAgregados - pago.cuposDescontados

	const periodosConVigencia = lodash.filter(pago.periodosDeCupos, periodo => fechaDeComparacion.isBefore(periodo.fechaFin))
	const cuposRestantes = lodash.sumBy(periodosConVigencia, periodo => plan.cupos - periodo.stats.cuposUsados)

	const limiteDeCupos = (plan.cupos * lodash.size(pago.periodosDeCupos))
	const disponibles = Math.min(limiteDeCupos, cuposRestantes) + alteracionDeCuposDelPago

	return {
		disponibles,
		recuperables: segunRecuperabilidad.recuperables,
		noRecuperables: segunRecuperabilidad.noRecuperables
	}
}


export function CalcularPosibilidadesDeReservaConMembresia(datos: {
	gimnasio: Gimnasio
	instancia: InstanciaCalculada
	membresia: Membresia
}): PosibilidadDeReserva[] {
	// const fx = 'CalcularPosibilidadesDeReservaConMembresia'
	const { gimnasio, instancia, membresia } = datos

	try {

		// * Programas
		const plan = gimnasio.planes[membresia.planID]
		const membresiaProgramaIDs = plan.programasIDs
		const clase = gimnasio.clases[instancia.claseID]
		const instanciaProgramaIDs = clase.programasIDs
		const programasCompatibles = lodash.intersection(
			membresiaProgramaIDs,
			instanciaProgramaIDs
		)
		if (lodash.isEmpty(programasCompatibles))
			return []
		if (!membresia.activa)
			return []

		// * Pagos vigentes
		const pagosVigentesParaInstancia = lodash.pickBy(membresia.pagos, (pago) => {
			return dayjs(instancia.fechaInicio).isBetween(pago.inicioVigencia, pago.finVigencia, 'day', '[]')
		})
		if (lodash.isEmpty(pagosVigentesParaInstancia))
			return []

		const posibilidades: PosibilidadDeReserva[] = []

		for (const pago of lodash.values(pagosVigentesParaInstancia)) {

			// * Cupos en periodo de cupos vigente

			const cicloDeCupos = lodash.find(pago.periodosDeCupos, periodo => dayjs(instancia.fechaInicio).isBetween(periodo.fechaInicio, periodo.fechaFin, 'day', '[]'))

			if (!cicloDeCupos) {
				console.warn('No se encontró periodo de cupos para la instancia', { membresiaID: membresia.membresiaID, pagoID: pago.pagoID, instanciaID: instancia.instanciaID, periodosDeCupos: pago.periodosDeCupos, pagoInicioVigencia: pago.inicioVigencia, pagoFinVigencia: pago.finVigencia, instanciaFechaInicio: instancia.fechaInicio })
				continue
			}

			const reservasDelCicloDeCupos = lodash.filter(cicloDeCupos.reservas, r => !r.eliminacion)
			
			const reservasYaExistentes: ReservaEnPago[] = []

			for (const reserva of reservasDelCicloDeCupos) {
				if (reserva.claseID === instancia.claseID && reserva.horarioID === instancia.horarioID && reserva.fechaYMD === instancia.fechaYMD)
					reservasYaExistentes.push(reserva)
			}


			const sabadoLibre = plan.sabadoLibre && dayjs(instancia.fechaYMD).isoWeekday() === 6

			const cuposNoAgendadosEnPeriodo = (plan.cupos ?? 0) - cicloDeCupos.stats.cuposUsados

			const limiteDeCuposDelPago = (plan.cupos * lodash.size(pago.periodosDeCupos)) + pago.cuposAgregados - pago.cuposDescontados
			
			const cuposUsadosEnPago = lodash.sum(lodash.map(pago.periodosDeCupos, p => p.stats.cuposUsados))
			
			// * Limites diarios

			const reservasMismoDia = lodash.filter(reservasDelCicloDeCupos, r => dayjs(r.fechaInicio).isSame(instancia.fechaInicio, 'day'))
			const cantidadDeReservasMismoDia = lodash.size(reservasMismoDia)

			const reservasDelDiaPorPrograma = lodash.reduce(instanciaProgramaIDs, (acum, programaID) => {
				const reservasMismoDiaYMismoPrograma = lodash.filter(reservasMismoDia, (i) => {
					const clase = gimnasio.clases[i.claseID]
					const suProgramaIDs = clase.programasIDs
					return suProgramaIDs.includes(programaID)
				})
				acum[programaID] = reservasMismoDiaYMismoPrograma.length
				return acum
			}, {} as Record<string, number>)

			// * Si hay más reservas que los cupos del ciclo, se considera que se usó cupos
			// * Calcular los cupos agregados que se usaron en todo el pago (todos los ciclos)
			const cuposUsadosSobreElLimiteDelCiclo = lodash.reduce(pago.periodosDeCupos, (acumulado, periodo) => {
				
				const cuposSobreLimiteDelPeriodo = periodo.stats.cuposUsados - plan.cupos
				if (cuposSobreLimiteDelPeriodo > 0) acumulado += cuposSobreLimiteDelPeriodo
				return acumulado
			}, 0)

			const cuposAgregadosDisponibles = (pago.cuposAgregados - pago.cuposDescontados) - cuposUsadosSobreElLimiteDelCiclo

			const maximoDiarioAlcanzado = gimnasio.restricciones.limitarReservasDiariasPorMembresia
				? lodash.size(reservasMismoDia) >= plan.maximoReservasDiarias
				: !lodash.some(reservasDelDiaPorPrograma, (reservas) => {
					const maximo = plan.maximoReservasDiarias
					if (!maximo)
						return false
					return reservas < maximo
				})
			
			// * Conclusiones

			// * Si ya tiene una reserva, y el plan no permite multiples reservas por sesion, no puede reservar
			const puedeReservarEstaSesion: boolean = (lodash.isEmpty(reservasYaExistentes) || plan.configuraciones?.multiplesReservasPorSesion) ?? false

			const puedeReservarPorCupoDia: boolean = !!(plan.cupoPorDia && cantidadDeReservasMismoDia)

			const puedeReservarSinUsarCupo: boolean = plan.cuposIlimitados || sabadoLibre || puedeReservarPorCupoDia

			// const 
			const puedeReservarPorCupos: boolean = puedeReservarSinUsarCupo ||
				(limiteDeCuposDelPago > cuposUsadosEnPago) && ((cuposNoAgendadosEnPeriodo + cuposAgregadosDisponibles) > 0)

			const elegible = puedeReservarEstaSesion && puedeReservarPorCupos && !maximoDiarioAlcanzado

			// * Final
			const posibilidad: PosibilidadDeReserva = {
				posibilidadID: `${membresia.membresiaID}>${pago.pagoID}`,
				membresiaID: membresia.membresiaID,
				pagoID: pago.pagoID,
				instanciaID: instancia.instanciaID,
				fechaInicio: instancia.fechaInicio,
				periodoDeCupos: cicloDeCupos,

				reservaIDs: lodash.map(reservasYaExistentes, 'reservaID'),

				sabadoLibre,
				utilizaCupo: !sabadoLibre,
				cuposIlimitados: plan.cuposIlimitados,
				cuposDisponibles: cuposNoAgendadosEnPeriodo,
				cuposAgregadosDisponibles,

				maximoReservasDiarias: plan.maximoReservasDiarias,
				cantidadDeReservasMismoDia,
				cupoPorDia: plan.cupoPorDia,
				reservasDelDiaPorPrograma,
				maximoDiarioAlcanzado,

				puedeReservarPorCupoDia,
				puedeReservarPorCupos,
				puedeReservarPorReservas: puedeReservarEstaSesion,
				elegible
			}
			posibilidades.push(posibilidad)
		}

		// Resultados
		return posibilidades
	}
	finally {
		// consolo.groupEnd()
	}
}

export function CalcularPosibilidadesDeReservaPorMembresias(datos: {
	gimnasio: Gimnasio
	instancia: InstanciaCalculada
	membresias: Record<string, Membresia>
}): PosibilidadDeReserva[] {
	const { gimnasio, instancia, membresias } = datos
	if (lodash.isEmpty(membresias))
		return []

	const posibilidadesDeReserva: PosibilidadDeReserva[] = []
	for (const membresia of lodash.values(membresias)) {
		const posibilidades = CalcularPosibilidadesDeReservaConMembresia({ gimnasio, instancia, membresia })
		posibilidadesDeReserva.push(...posibilidades)
	}
	return posibilidadesDeReserva
}

export function RevisarSiUsaSalaYEligeUnLugarValido(datos: {
	gimnasio: Gimnasio
	instancia: Instancia
	lugarID?: string
}): { reservable: boolean; motivo?: string; lugarSugerido?: string } {
	const { gimnasio, instancia, lugarID } = datos

	const clase = gimnasio.clases[instancia.claseID]
	const horario = clase.horarios[instancia.horarioID]

	const reservasVigentes = lodash.filter(instancia.reservas, r => !r.eliminacion)

	if (!horario.salaID) {
		if (lugarID) {
			return {
				reservable: false,
				motivo: 'No se puede elegir un lugar en una clase que no usa sala'
			}
		}
		return { reservable: true }
	}
	if (!lugarID) {
		return {
			reservable: false,
			motivo: 'Se debe elegir un lugar'
		}
	}

	const sala = gimnasio.salas[horario.salaID]
	if (!sala) {
		return {
			reservable: false,
			motivo: 'Sala no encontrada'
		}
	}

	const lugaresReservados = new Set<string>()
	for (const reserva of reservasVigentes) {
		if (reserva.lugarID) {
			lugaresReservados.add(reserva.lugarID)
		}
	}

	const lugaresDeLaSala = new Set<string>()
	const lugaresUtilizablesDeLaSala = new Set<string>()
	const lugaresDisponibles = new Set<string>()

	for (const columna of Object.values(sala.columnas)) {
		for (const [lugarID, elemento] of Object.entries(columna.elementos)) {
			lugaresDeLaSala.add(lugarID)
			if (elemento.estado === 'no_disponible')
				continue
			lugaresUtilizablesDeLaSala.add(lugarID)
			if (!lugaresReservados.has(lugarID))
				lugaresDisponibles.add(lugarID)
		}
	}

	if (!lugaresDeLaSala.has(lugarID)) {
		return {
			reservable: false,
			motivo: 'Lugar no encontrado'
		}
	}

	if (!lugaresUtilizablesDeLaSala.has(lugarID)) {
		return {
			reservable: false,
			motivo: 'Lugar no utilizable'
		}
	}

	if (!lugaresDisponibles.has(lugarID)) {
		return {
			reservable: false,
			motivo: 'Lugar no disponible'
		}
	}

	return {
		reservable: true
	}
}

export function BuscarLugarDisponible(datos: {
	gimnasio: Gimnasio
	instancia: Instancia
}): string | undefined {
	const { gimnasio, instancia } = datos

	const clase = gimnasio.clases[instancia.claseID]
	const horario = clase.horarios[instancia.horarioID]

	if (!horario.salaID)
		return undefined

	const sala = gimnasio.salas[horario.salaID]
	if (!sala)
		return undefined

	const lugaresReservados = new Set<string>()
	for (const reserva of lodash.filter(instancia.reservas, r => !r.eliminacion)) {
		if (reserva.lugarID) {
			lugaresReservados.add(reserva.lugarID)
		}
	}

	const lugaresDeLaSala = new Set<string>()
	const lugaresUtilizablesDeLaSala = new Set<string>()
	const lugaresDisponibles = new Set<string>()

	for (const columna of Object.values(sala.columnas)) {
		for (const [lugarID, elemento] of Object.entries(columna.elementos)) {
			lugaresDeLaSala.add(lugarID)
			if (elemento.estado === 'no_disponible')
				continue
			lugaresUtilizablesDeLaSala.add(lugarID)
			if (!lugaresReservados.has(lugarID))
				lugaresDisponibles.add(lugarID)
		}
	}
	return lugaresDisponibles.values().next().value
}

export function DeterminarReservabilidadPorTiemposDeInstancia(datos: {
	gimnasio: Gimnasio
	instanciaInicioDate: Date
	instanciaFinDate: Date
}): Reservabilidad {
	const { gimnasio, instanciaInicioDate, instanciaFinDate } = datos
	const instanciaInicio = dayjs(instanciaInicioDate)
	const instanciaFin = dayjs(instanciaFinDate)

	// Revisar que la reserva sea antes de que termine la clase
	const ahora = dayjs()

	if (instanciaFin.isBefore(ahora)) {
		return {
			reservable: false,
			motivo: 'sesionConcluida'
		}
	}

	// Hora de apertura
	const minsParaApertura = gimnasio.restricciones.reservaAperturaMins
	// console.log('minsParaApertura', minsParaApertura)
	const limitarAperturaDeReservas = typeof minsParaApertura === 'number'
	if (limitarAperturaDeReservas) {
		// * minsParaApertura Puede ser 0
		const limiteApertura = instanciaInicio.subtract(minsParaApertura, 'm')

		// console.log('limiteApertura', limiteApertura?.format('h:mma'))

		if (ahora.isBefore(limiteApertura)) {
			return {
				reservable: false,
				motivo: 'limiteApertura'
			}
		}
	}

	// Antelacion minima
	const minsDeAntelacion = gimnasio.restricciones.reservaAntelacionMins
	const limiteAntelacion
		= minsDeAntelacion && instanciaInicio.subtract(minsDeAntelacion, 'm')
	if (minsDeAntelacion && ahora.isAfter(limiteAntelacion)) {
		return {
			reservable: false,
			motivo: 'minsDeAntelacion'
		}
	}

	// Minutos reservables tras inicio
	const minsReservablesTrasInicio = gimnasio.restricciones.reservaTrasInicioMins
	if (minsReservablesTrasInicio) {
		const limiteReservablesTrasInicio = dayjs(instanciaInicio).add(minsReservablesTrasInicio, 'm')

		if (ahora.isAfter(limiteReservablesTrasInicio)) {
			return {
				reservable: false,
				motivo: 'reservaTrasInicioMins'
			}
		}
	}

	return {
		reservable: true
	}
}
