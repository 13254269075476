<template lang="pug">
div.appRoot.usn
	NuxtPage(:keepAlive="true")

	UiVersion
	UiModales

	img.posa.t0.h1em.w1em.op0.intocable(v-if="!esAppGold" src="/logos/boxmagic-icono.svg" width="120" alt="Boxmagic")
	
	UiNotificaciones
</template>

<script lang="ts" setup>
onMounted(() => {
	TemaOscuroAPI.propagarTema()
	setTimeout(() => TemaOscuroAPI.propagarTema(), 300)
	setTimeout(() => TemaOscuroAPI.propagarTema(), 1200)
	setTimeout(() => TemaOscuroAPI.propagarTema(), 2000)
})
</script>

<style lang="sass">
@use "@base/sass/comun"

.logoBoxmagic
	height: 1em
	width: 3.81em
	// max-width: 9rem
	$logoUbicacion: '/logos/claro.svg'
	&.b
		background-image: url(#{$logoUbicacion})
		background-position: center left
		+comun.bgcon
	&.m
		mask-image: url(#{$logoUbicacion})
		+comun.micon
		background-color: currentColor
html.oscuro .logoBoxmagic
	$logoUbicacion: '/logos/oscuro.svg'
	&.b
		background-image: url(#{$logoUbicacion})
	&.m
		mask-image: url(#{$logoUbicacion})
</style>

<style lang="sass" scoped>
html:not(.claro):not(.oscuro) .invisibleEnCarga
	visibility: hidden
	transition: all .3s ease .3s
.dev
	position: fixed
	top: 0
	left: 0
	margin: 1rem
	background-color: #333
	color: #999

// Modal
body.afectadoPorModal .appRoot
	touch-action: none

</style>
