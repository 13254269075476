import axios from 'axios'
import { crearStorage } from '../../lib/localforage'
import _ from 'lodash'
import { z } from 'zod'
import { LocalNotifications } from '@capacitor/local-notifications'
import type {
	ActionPerformed,
	PushNotificationSchema,
	Token
} from '@capacitor/push-notifications'
import { PushNotifications as CapacitorPushNotifications } from '@capacitor/push-notifications'

import { ErrorAumentado } from '@comun/error'
import type { NotificacionAumentada } from '@comun/notificaciones.types'
import { contextoApp } from '../../composables/contextoApp'
import { consolo } from '../../composables/consolo'
import emisorEventosObjeto from '../../lib/emisorEventosObjeto'

import { ErrorNotificableZod } from '../erroresNotificables'

const notiCache = crearStorage('notiStore')

const tokenDestinatarioRef = ref<string | null>(null)
export const tokenDestinatario = computed(() => tokenDestinatarioRef.value)

async function suscribirme(token: string) {
	const fx = 'suscribirme'
	try {
		const tokenUsuario = unref(token)
		if (!tokenUsuario) {
			consolo.warn(fx, 'no hay token de usuario')
			return false
		}
		const headers = tokenUsuario ? HeadersConAuth() : HeadersBase()

		const respuesta = await axios({
			url: `${contextoApp.buildConfig.apiUrl}/notificador/suscribir`,
			method: 'post',
			headers,
			data: { token }
		})
			.then(r => r.data)
			

		const parseo = z.discriminatedUnion('ok', [
			z.object({
				ok: z.literal(true)
			}),
			z.object({
				ok: z.literal(false),
				error: ErrorNotificableZod
			})
		]).safeParse(respuesta)

		if (!parseo.success)
			throw new ErrorAumentado(fx, { error: parseo.error })
		if (!parseo.data.ok)
			throw new ErrorAumentado(fx, { error: parseo.data.error })

		consolo.log(fx, 'ok?', respuesta.ok)

		return respuesta.ok
	}
	catch (e) {
		if (e instanceof ErrorAumentado)
			throw e.trazar(fx)
		console.error('error', e)
		throw new ErrorAumentado(fx, { error: e })
	}
}

async function inicializarReceptorPushNativo() {
	consolo.log('inicializarReceptorPushNativo')

	// On success, we should be able to receive notifications
	CapacitorPushNotifications.addListener('registration', (token: Token) => {
		console.warn(`PUSH: Push registration success, token: ${token.value}`)
		suscribirme(token.value)
	})

	// Some issue with our setup and push will not work
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	CapacitorPushNotifications.addListener('registrationError', (error: any) => {
		console.warn(`PUSH: Error on registration: ${JSON.stringify(error)}`)
	})

	// Show us the notification payload if the app is open on our device
	CapacitorPushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
		console.warn(`PUSH: Push recibido con app abierta: ${JSON.stringify(notification)}`)

	})
	// PUSH: Push received:
	// const ejemplo = {
	// 	data: {
	// 		"aps": {
	// 			alert: {
	// 				body: 'Esto es una notificación de prueba',
	// 				title: 'Notificación de prueba'
	// 			}
	// 		},
	// 		"google.c.sender.id": '544236061744',
	// 		"google.c.fid": 'eDTod274s07sgs-Laru40F',
	// 		"gcm.message_id": '1717449892490689',
	// 		"foo": 'bar',
	// 		"google.c.a.e": '1'
	// 	},
	// 	title: 'Notificación de prueba',
	// 	id: '434F588C-0D64-4FC7-B0B3-0B758D0464E7',
	// 	badge: 1,
	// 	body: 'Esto es una notificación de prueba',
	// 	subtitle: ''
	// }

	// Method called when tapping on a notification
	CapacitorPushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed: ActionPerformed) => {
		console.warn(`PUSH: Push action performed: ${JSON.stringify(actionPerformed)}`)
		// const actionId = actionPerformed.actionId
		const data = actionPerformed.notification.data

		const parserZod = z.discriminatedUnion('tipo', [
			z.object({
				tipo: z.literal('recordatorioDeInstancia'),
				instanciaID: z.string(),
				usuarioID: z.string()
			}),
			z.object({
				tipo: z.literal('prioridadPorEspera'),
				instanciaID: z.string(),
				usuarioID: z.string()
			})
		])

		const parseo = parserZod.safeParse(data)
		if (parseo.success) {
			const { instanciaID } = parseo.data

			// * Abrir modal de la instancia
			EventosDeAppAPI.emitirEventoDeApp('instancia:abrirModal', { instanciaID })
		}
	})

	// Ejemplo de notificación:
	// PUSH: Push action performed:
	// const ejemplo = {
	// 	"actionId": "tap",
	// 	"notification": {
	// 		"title": "Se acabó la espera, hay una vacante!",
	// 		"subtitle": "",
	// 		"id": "F5E791D4-D565-4D60-9544-3856871B2892",
	// 		"body": "Tienes unos segundos de acceso prioritario para agendar la sesión.",
	// 		"data": {
	// 			"google.c.a.e": "1",
	// 			"google.c.fid": "dCDjJ4y4K0BnrLmD144h3B",
	// 			"gcm.message_id": "1719555824697527",
	// 			"tipo": "prioridadPorEspera",
	// 			"aps": {
	// 				"alert": {
	// 					"body": "Tienes unos segundos de acceso prioritario para agendar la sesión.",
	// 					"title": "Se acabó la espera, hay una vacante!"
	// 				}
	// 			},
	// 			"instanciaID": "i2024-06-29>oV4dgEgLr2>zd4RMo2bL7",
	// 			"solicitudID": "07ZwxaLOLS", "google.c.sender.id": "544236061744"
	// 		}, "badge": 1
	// 	}
	// }

	// Request permission to use push notifications
	// iOS will prompt user and return if they granted permission or not
	// Android will just grant without prompting

	await CapacitorPushNotifications.checkPermissions().then((result) => {
		consolo.log('PUSH: requestPermissions', result)
		if (result.receive === 'granted') {
			// Register with Apple / Google to receive push via APNS/FCM
			consolo.log('PUSH: register')
			return CapacitorPushNotifications.register()
		}
	})
}

async function pedirPermisoPush() {
	const fx = 'pedirPermisoPush'
	try {
		await CapacitorPushNotifications.requestPermissions().then((result) => {
			consolo.log('PUSH: requestPermissions', result)
			if (result.receive === 'granted') {
				// Register with Apple / Google to receive push via APNS/FCM
				consolo.log('PUSH: register')
				return CapacitorPushNotifications.register()
			}
		})
	}
	catch (e) {
		console.error(fx, e)
	}
}



export const ConectorNativo = {
	...emisorEventosObjeto,

	async init() {
		const notificacionesPermitidas = ConectorNativo.leerPermiso()

		ConectorNativo.emit('cambioPermiso', notificacionesPermitidas)

		inicializarReceptorPushNativo()
		LocalNotifications.removeAllListeners()
		LocalNotifications.addListener('localNotificationReceived', (n) => {
			consolo.log('notification recibida', n, `type: ${typeof n}`)
		})
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(a) => {
				consolo.log('accion ejecutada', a, `type: ${typeof a}`)
				const data = _.get(a, 'notification.extra')
				ConectorNativo.emit('notificationClick', data)
			}
		)
	},
	async leerPermiso(): Promise<boolean> {
		consolo.log('locales>leerPermiso')

		const { display } = await LocalNotifications.checkPermissions()
		const notificacionesPermitidas = display === 'granted'

		return notificacionesPermitidas
	},
	async pedirPermiso(): Promise<boolean> {
		consolo.log('locales>pedirPermiso')

		await pedirPermisoPush()

		const r = await LocalNotifications.requestPermissions()
		consolo.log('locales>pedirPermiso, r:', r)
		const notificacionesPermitidas = r.display === 'granted'


		this.emit('cambioPermiso', notificacionesPermitidas)
		return notificacionesPermitidas
	},
	async obtenerNuevoID() {
		let idPrevio = (await notiCache.getItem<number>('ultimoID')) || 0
		consolo.log(
			'notificacionesLocales>ahora idPrevio',
			idPrevio,
			'typeof',
			typeof idPrevio
		)
		if (Number.isNaN(idPrevio))
			idPrevio = 0
		await notiCache.setItem<number>('ultimoID', idPrevio + 1)
		return idPrevio + 1
	},

	async programar(notificacion: NotificacionAumentada) {
		const id = await ConectorNativo.obtenerNuevoID()
		await LocalNotifications.schedule({
			notifications: [{
				title: notificacion.titulo,
				body: notificacion.texto,
				id,
				schedule: { at: notificacion.fechaEnQueMostrar },
				extra: {
					...notificacion.data,
					texto: notificacion.texto,
					url: notificacion.urlQueAbrir
				}
			}]
		})
	},


	async cancelarTodas() {
		consolo.log('notificacionesLocales>cancelarTodas')
		const { notifications } = await LocalNotifications.getPending()
		if (_.isEmpty(notifications))
			return
		await LocalNotifications.cancel({ notifications })
		return 'Notificaciones canceladas'
	}
}
