import { ErrorAumentado } from '@base/lib/error'
import { stringify as StringifyQS } from 'qs'


import { i18nTapi, ErrorNotificableZod, i18nIconos } from '../lib/erroresNotificables'
import { z } from 'zod'


// ----------------------------------------------
// Tipado (para definir estructura y detectar errores)

const CategoriaZod = z.object({
	categoriaID: z.string(),
	gimnasioID: z.string(),

	nombre: z.string(),
	activo: z.boolean(),
	// created_at: z.string(),
	// updated_at: z.string(),
	imagen: URLOpcionalZod
})
export type Categoria = z.infer<typeof CategoriaZod>

export const RespuestaCategoriasZod = z.object({
	ok: z.literal(true),
	categorias: z.array(CategoriaZod)
})
export const RespuestaCategoriasError = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})
export const ParserCategorias = z.discriminatedUnion('ok', [
	RespuestaCategoriasZod,
	RespuestaCategoriasError
])

// ----------------------------------------------
// ----------------------------------------------
// ----------------------------------------------

export const HorarioZod = z.object({
	horarioID: z.string(),
	reservaID: StringOpcionalZod,

	fechaInicio: FechaADayjsZod,
	fechaFin: FechaADayjsZod,

	urlReserva: z.string(),

	precio: z.object({
		monto: z.number(),
		moneda: z.enum(['CLP', 'USD'])
	}),

	reservada: BooleanOFalsoZod,
	reservadaPorUsuario: BooleanOFalsoZod
})
export type Horario = z.infer<typeof HorarioZod>

export const CanchaZod = z.object({
	canchaID: z.string(),
	categoriaID: z.string(),

	imagen: URLOpcionalZod,
	nombre: z.string(),
	descripcion: StringOpcionalZod,
	horarios: z.array(HorarioZod)
})
export type Cancha = z.infer<typeof CanchaZod>

export const respuestaCanchasZodEnFecha = z.object({
	ok: z.literal(true),
	instancias: z.array(CanchaZod)
})
export const respuestaCanchasEnFechaError = z.object({
	ok: z.literal(false),
	error: ErrorNotificableZod
})
export const parserCanchasEnFecha = z.discriminatedUnion('ok', [
	respuestaCanchasZodEnFecha,
	respuestaCanchasEnFechaError
])

const consoloRaiz = 'Lib TAPI Canchas'
const consoloColor = 'color: OliveDrab'

const categoriasCanchaRef = ref<Categoria[]>([])
export const categoriasCancha = computed(() => categoriasCanchaRef.value)
async function ObtenerCategorias(): Promise<boolean> {
	const fx = 'ObtenerCategorias'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		const headers = HeadersConAuth()

		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/canchas/categorias`,
			method: 'get',
			headers
		})
			

		const parseroCategoriasCanchas = ParserCategorias.safeParse(respuesta)
		if (!parseroCategoriasCanchas.success) {
			// Reportar
			throw new ErrorAumentado('ErrorDeParseo', {
				datos: {
					parser: 'parseroCategoriasCanchas'
				},
				error: parseroCategoriasCanchas.error
			})
		}

		if (parseroCategoriasCanchas.data.ok === false) {
			const errorID = parseroCategoriasCanchas.data.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: {
					boxNoEncontrado: 'tabler:home-question'
				}[errorID]
			})
			return false
		}
		// const { canchas } = respuestaCanchasEnFechaOk.parse(respuesta)
		categoriasCanchaRef.value = parseroCategoriasCanchas.data.categorias

		return true
	}
	catch (e) {
		consolo.error('error', e)
		throw e
	}
	finally {
		consolo.groupEnd()
	}
}

// == Canchas en fecha ==
type RegistroDeCanchasEnFecha = Record<string, Cancha[]>
const registroDeCanchasEnFechaRef = ref<RegistroDeCanchasEnFecha>({})

const fechaDeExploracionRef = ref<Dayjs>(dayjs())

export const espaciosEnFecha = computed(() => {
	const fechaYMD = fechaDeExploracionRef.value.format('YYYY-MM-DD')
	const canchas = registroDeCanchasEnFechaRef.value[fechaYMD]
	return canchas
})

export const espaciosFechaVisible = computed(() => fechaDeExploracionRef.value)

async function EnFecha(fecha: Dayjs): Promise<boolean> {
	const fx = 'EnFecha'
	consolo.group(`%c${consoloRaiz} ${fx}`, consoloColor)
	try {
		fechaDeExploracionRef.value = fecha
		const headers = HeadersConAuth()
		const respuesta = await comoSiFueraPorAxios({
			url: `${contextoApp.buildConfig.apiUrl}/boxmagic/canchas/enfecha?${StringifyQS({
				fecha: fecha.format('YYYY-MM-DD')
			})}`,
			method: 'get',
			headers
		})
			

		const parseoCanchasEnFecha = parserCanchasEnFecha.safeParse(respuesta)
		if (!parseoCanchasEnFecha.success) {
			// Reportar
			throw new ErrorAumentado('ErrorDeParseo', {
				datos: {
					parser: 'parseoCanchasEnFecha'
				},
				error: parseoCanchasEnFecha.error
			})
		}

		if (parseoCanchasEnFecha.data.ok === false) {
			const errorID = parseoCanchasEnFecha.data.error

			notificadorEnApp.atencion({
				titulo: i18nTapi('falloElIngreso'),
				texto: i18nTapi(errorID),
				codigo: errorID,
				icono: i18nIconos[errorID]
			})
			return false
		}

		const fechaYMD = fecha.format('YYYY-MM-DD')

		registroDeCanchasEnFechaRef.value = Object.assign(
			{},
			registroDeCanchasEnFechaRef.value,
			{
				[fechaYMD]: parseoCanchasEnFecha.data.instancias
			}
		)

		return true
	}
	catch (e) {
		consolo.error('error', e)
		throw e
	}
	finally {
		consolo.groupEnd()
	}
}

function Limpiar() {
	categoriasCanchaRef.value = []
	registroDeCanchasEnFechaRef.value = {}
	fechaDeExploracionRef.value = dayjs()
}

export const EspaciosAPI = {
	Limpiar,
	ObtenerCategorias,
	EnFecha
}
