import type { ContextoApp } from '@frontComun/estructuraContextoApp'
import type { NuxtAppConfig } from 'nuxt/schema'

export function armarHead(ctxApp: ContextoApp): NuxtAppConfig['head'] {
	const head: NuxtAppConfig['head'] = {
		title: (ctxApp ?? contextoApp).appConfig.nombre,
		meta: [
			{ charset: 'utf-8' },
			{
				hid: 'viewport',
				name: 'viewport',
				content:
					'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no, viewport-fit=cover, shrink-to-fit=no'
			},
			{ hid: 'description', name: 'description', content: ctxApp.appConfig.descripcion },
			// ANDROID
			{
				hid: 'mobile-web-app-capable',
				name: 'mobile-web-app-capable',
				content: 'yes'
			},
			// IOS
			{
				hid: 'apple-mobile-web-app-title',
				name: 'apple-mobile-web-app-title',
				content: ctxApp.appConfig.nombre
			},
			{
				hid: 'apple-mobile-web-app-capable',
				name: 'apple-mobile-web-app-capable',
				content: 'yes'
			},
			{
				hid: 'apple-mobile-web-app-status-bar-style',
				name: 'apple-mobile-web-app-status-bar-style',
				content: 'default'
			},
			// OTROS
			{
				hid: 'msapplication-tap-highlight',
				name: 'msapplication-tap-highlight',
				content: ''
			},
			// Desactivar darkreader
			{
				hid: 'darkreader',
				name: 'darkreader-lock',
				content: ''
			}
		],
		link: [
			{
				hid: 'favicon',
				rel: 'icon',
				type: 'image/svg+xml',
				href: '/favicon.svg'
			},
			{
				hid: 'mask-icon',
				rel: 'icon',
				type: 'image/svg+xml',
				href: '/favicon.svg'
			},

			{ hid: 'baseCSS', rel: 'stylesheet', href: '/css/porDefecto.css' }
		],
		script: ctxApp.buildConfig.gTagManager
			? [{
				hid: 'gtm',
				async: true,
				src: `https://www.googletagmanager.com/gtm.js?id=${ctxApp.buildConfig.gTagManager}`,
				tagPosition: 'bodyClose'
			}, {
				hid: 'gtmJS',
				tagPosition: 'bodyClose',
				innerHTML:
					'window.dataLayer = window.dataLayer || [];window.dataLayer.push({ \'gtm.start\': new Date().getTime(), event: \'gtm.js\' })'
			}]
			: [],
		noscript: ctxApp.buildConfig.gTagManager
			? [{
				hid: 'gtmNoScript',
				tagPosition: 'bodyClose',
				innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${ctxApp.buildConfig.gTagManager}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
			}]
			: []
	}

	if (ctxApp.buildConfig.gTagManager) {
		head.script = [{
			hid: 'gtm',
			async: true,
			src: `https://www.googletagmanager.com/gtm.js?id=${ctxApp.buildConfig.gTagManager}`,
			tagPosition: 'bodyClose'
		}, {
			hid: 'gtmJS',
			tagPosition: 'bodyClose',
			innerHTML:
				'window.dataLayer = window.dataLayer || [];window.dataLayer.push({ \'gtm.start\': new Date().getTime(), event: \'gtm.js\' })'
		}]
		head.noscript = [{
			hid: 'gtmNoScript',
			tagPosition: 'bodyClose',
			innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${ctxApp.buildConfig.gTagManager}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
		}]
	}

	return head
}

