<template lang="pug">
#preparacionRoot.h100dvh.minh100dvh
	.cubreStatusBar
	slot
</template>

<script lang="ts" setup>

onMounted(() => {
	consolo.log('Preparacion: onMounted')
})
</script>

<style lang="sass" scoped>
#preparacionRoot
	overflow-y: auto
</style>
